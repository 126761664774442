import React from 'react';

const ConfirmModal = ({ clubName, onClose, onConfirm }) => {
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-100">
            <div className="bg-[#2a1b3e] p-6 rounded-lg shadow-xl max-w-sm w-full mx-4">
                <h3 className="text-xl font-bold mb-4">Подтверждение</h3>
                <p className="text-gray-300 mb-6">
                    Вы уверены, что хотите вступить в клуб "{clubName}"?
                </p>
                <div className="flex justify-end gap-4">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 rounded-lg bg-gray-600 hover:bg-gray-700 transition-colors"
                    >
                        Отмена
                    </button>
                    <button
                        onClick={onConfirm}
                        className="px-4 py-2 rounded-lg bg-purple-600 hover:bg-purple-700 transition-colors"
                    >
                        Подтвердить
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmModal; 