import React, { useState } from 'react';
import { CustomModalReact, CustomModalMintNft } from './CustomModalReact';

const Marketplace = () => {
    const [open, setOpen] = useState(false);
    const [selectedNft, setSelectedNft] = useState(null);
    const marketplaceNFTs = [
        {   index: 0,
            name: 'Футболка',
            price: '5 TON',
            image: '/1.jpg',
            description: 'Футболка с логотипом Drive Coin'
        },
        {   index: 1,
            name: 'Бейсболка',
            price: '5 TON',
            image: '/1.jpg',
            description: 'Бейсболка с логотипом Drive Coin'
        },
        {   index: 2,
            name: 'ELM 327',
            price: '5 TON',
            image: '/1.jpg',
            description: 'Фирменная ELM 327 с логотипом Drive Coin'
        }
    ];

    return (
        <>
        <div className="mt-8 mb-[200px]">
            <h1 className="text-left text-white text-2 mb-4">Marketplace</h1>
            
            <div className="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-3 gap-2">
                {marketplaceNFTs.map((nft, index) => (
                    <div key={index} className="bg-gradient-to-br from-[#2e1065]/50 to-[#4c1d95]/50 p-0 overflow-hidden rounded-lg shadow-md relative">
                        <div className="relative">
                            <img 
                                src={require(`../img/nft_image${nft.image}`)}
                                alt={nft.name}
                                className="w-full h-auto mb-2"
                                onClick={() => {setOpen(true); setSelectedNft(nft.index);}} 

                            />
                            <div className="absolute top-2 right-2 bg-gradient-to-br from-[#2e1065]/90 to-[#4c1d95]/90 rounded-md p-0.5">
                                <span className="text-xs font-sm font-bold text-gray-400 p-0 line-clamp-1">
                                    {nft.price}
                                </span>
                            </div>
                        </div>
                        <p className="text-sm text-center text-gray-400 overflow-hidden p-1">
                            {nft.name}
                        </p>
                    </div>
                ))}
            </div>
        </div>
        <CustomModalMintNft  isOpen={open} onClose={() => setOpen(false)}>
            <div className="flex flex-col items-center justify-center text-center gap-4 h-[95vh]">
               
                <div className="flex flex-col items-center justify-center text-center gap-4">
                  {marketplaceNFTs.filter(nft => nft.index === selectedNft).map((nft) => (
                    <div> 
                     <h1 className="text-left text-white text-2 mb-4">{nft.name}</h1>
                    <img src={require(`../img/nft_image${nft.image}`)}  className="w-full h-auto mb-2" />
                   
                    <div className="text-sm text-center text-gray-400 overflow-hidden p-1">{nft.description}</div>
                    <div className="text-sm text-center text-gray-400 overflow-hidden p-1">{nft.price}</div>
                    <button onClick={() => { }} 
                            className="rounded-3xl bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] shadow-lg p-2.5 shadow-indigo-500/50 w-full">
                          Buy 
                    </button>
                    </div>

                  ))}
                </div>
            </div>
        </CustomModalMintNft>
        </>
    );
};

export default Marketplace; 