import { useEffect } from 'react';

const SessionManager = () => {
  // Генерация уникального ID сессии
  const generateSessionId = () => {
    const timestamp = Date.now();
    const random = Math.floor(Math.random() * 1000000);
    return `session_${timestamp}_${random}`;
  };

  useEffect(() => {
    // Проверяем состояние WebApp каждые 500мс пока не инициализируется
    const initCheckInterval = setInterval(() => {
      const tg = window.Telegram?.WebApp;
      
      if (!tg) {
        console.log('Ожидание инициализации WebApp...');
        return;
      }

      // Если WebApp инициализирован
      if (tg.initDataUnsafe) {
        clearInterval(initCheckInterval);
        const currentSessionId = generateSessionId();
        
        console.log('WebApp инициализирован, создана сессия:', currentSessionId);

        // Сразу сохраняем новый sessionId, делая эту сессию активной
        localStorage.setItem('active_session', currentSessionId);
        console.log('Новая сессия инициализирована:', { sessionId: currentSessionId });

        // Мониторим изменения в storage через интервал
        const checkInterval = setInterval(() => {
          const currentStoredId = localStorage.getItem('active_session');
          if (currentStoredId && currentStoredId !== currentSessionId) {
            console.log('Обнаружена новая сессия, закрываем текущую:', { stored: currentStoredId, current: currentSessionId });
            tg.close();
          }
        }, 1000);

        // Мониторим изменения между вкладками
        const handleStorageChange = (e) => {
          if (e.key === 'active_session' && e.newValue && e.newValue !== currentSessionId) {
            console.log('Сессия изменилась в другой вкладке, закрываем текущую:', { stored: e.newValue, current: currentSessionId });
            tg.close();
          }
        };

        window.addEventListener('storage', handleStorageChange);

        // Очистка при размонтировании
        return () => {
          clearInterval(checkInterval);
          window.removeEventListener('storage', handleStorageChange);
          if (localStorage.getItem('active_session') === currentSessionId) {
            localStorage.removeItem('active_session');
            console.log('Сессия завершена:', { sessionId: currentSessionId });
          }
        };
      }
    }, 500);

    // Очистка интервала проверки инициализации при размонтировании
    return () => clearInterval(initCheckInterval);
  }, []);

  return null;
};

export default SessionManager; 