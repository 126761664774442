import React, { useContext, useState , useEffect} from 'react';
import { Button } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import { useTonConnect } from '../Hook/useTonconnect.ts';
import { UserContext } from '../UserContext';
import {useContractMint} from "../Hook/useContractNft.ts";

const GetTonModal = ( {onClose} ) => {
    const {sender, connected, wallet, network, tonAddress , tonConnectUI, tonConnectModal} = useTonConnect(); 
    const {ton_address,refParent1,refParent2,refParent3, ton_adress_sbt_level_1, ton_adress_sbt_level_2, ton_adress_sbt_level_3} = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [openschet , setOpenschet] = useState(false);
    const { mintsbt} = useContractMint();

    
    const sendMintSBT = async () => {
        // alert("ref1: " + refParent1 + " ref2: " + refParent2 + " ref3: " + refParent3 );
         try {
             if (!connected) {
                 toast.error('Please connect your wallet');
                 return;
             }
 
             await toast.promise(
                 (async () => {
                     let referent = ton_adress_sbt_level_1 ? ton_adress_sbt_level_1 : null;
                     await mintsbt(referent);
                   
                 })(),
                 {
                     pending: 'Creating SBT...',
                     success: 'SBT-контракт успешно создан. Все вознаграждения будут автоматически поступать на этот контракт',
                     error: {
                         render({data}) {
                             return `Transaction canceled`;
                         }
                     }
                 }
             );
 
         } catch (error) {
             if (error.message === 'USER_CANCELED_TRANSACTION') {
               
                 return;
             }
            
         }
     };
     const handleConnect = async () => {
        try {
            setOpenschet(true);
            onClose();
            tonConnectModal.open();
        } catch (error) {
            console.error("Error connecting to TON:", error);
        }
    }
     const handleMintSbt = async () => {
        try {
            onClose();
            await sendMintSBT();
        } catch (error) {
            console.error("Error connecting to TON:", error);
        }
    }
    useEffect(() => {
        if (tonAddress && openschet) {
            setTimeout(() => {
                sendMintSBT();
                setOpenschet(false);
            }, 3000);
            
        }
    }, [tonAddress]);
    
    return (
        
        <div className="">
            
            <div className="">
             <div className="header-modal ">
              
                <div className="modal-title text-white text-lg font-bold text-center">

                <h3 className="text-xl w-full text-center font-medium text-white mb-6 flex items-center justify-center gap-2">
                Как получить TON? 
               
                <img src={require(`../img/ton.png`)} alt="boost" className='w-6 h-6 bg-white rounded-full' />
            </h3>
                </div>
            </div>
            <div className="w-full justify-center  z-60 content-center items-center overflow-y-auto h-[80dvh]"> 
            <div className="space-y-4 text-left">
                <div className="bg-gradient-to-r from-violet-900/20 to-purple-900/20 p-2 rounded-lg border border-violet-500/10">
                    <h4 className="text-violet-300 font-medium mb-2 flex items-center gap-2">
                        <span className="w-6 h-6 flex items-center justify-center rounded-full bg-violet-500/20">1</span>
                      Три уровня реферальной программы в TON
                    </h4>
                    <p className="text-sm text-gray-300">
                        Получайте % от приглашенных рефералов в игру. Со всех операций рефералов Вам начисляется TON.
                    </p>
                </div>

                <div className="bg-gradient-to-r from-violet-900/20 to-purple-900/20 p-2 rounded-lg border border-violet-500/10">
                    <h4 className="text-violet-300 font-medium mb-2 flex items-center gap-2">
                        <span className="w-6 h-6 flex items-center justify-center rounded-full bg-violet-500/20">2</span>
                        Автоматические выплаты
                    </h4>
                    <p className="text-sm text-gray-300">
                        Смарт-контракт автоматически переводит TON на ваш счёт. Создайте счёт сейчас, чтобы начать получать выплаты.
                    </p>
                    { tonAddress ? (
                        <Button 
                            variant="primary"  
                            className="mt-4 w-full rounded-full bg-violet-500 hover:bg-violet-600"
                            onClick={handleMintSbt}
                        >
                            Создать счёт
                        </Button>
                    ) : (
                        <Button 
                            variant="primary"  
                            className="mt-4 w-full rounded-full bg-violet-500 hover:bg-violet-600"
                            onClick={handleConnect}
                        >
                           Создать счёт 
                        </Button>
                    )}
                </div>

                <div className="bg-gradient-to-r from-violet-900/20 to-purple-900/20 p-2 mb-20 rounded-lg border border-violet-500/10">
                    <h4 className="text-violet-300 font-medium mb-2 flex items-center gap-2">
                        <span className="w-6 h-6 flex items-center justify-center rounded-full bg-violet-500/20">3</span>
                        Дополнительные вознаграждения
                    </h4>
                    <p className="text-sm text-gray-300">
                        На этот же счёт вы будете получать все остальные вознаграждения в TON от приложения, а также сможете обменивать поинты на TON. Создайте счёт сейчас.
                    </p>
                </div>
                <div className="h-[100px]"> 
                    
                </div>
            </div>
        </div>
        </div>
        </div>
    );
};

export default GetTonModal; 