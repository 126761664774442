import React, { useState, useEffect, useRef } from 'react';
import Getcontext from './Getcontext.js';
import { motion, AnimatePresence } from 'framer-motion'; 

export function CustomModalReact({ isOpen, onClose, children }) {
    const [dragStart, setDragStart] = useState(0);

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    className="fixed inset-0   z-50 flex items-end justify-center sm:items-center"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    
                    onClick={onClose}
                >
                    <motion.div 
                        className=" bg-black show_shadow  rounded-t-[20px] sm:rounded-[20px] w-full sm:w-[600px] p-4 relative"
                        initial={{ y: '100%' }}
                        animate={{ y: 0 }}
                        exit={{ y: '100%' }}
                        transition={{ 
                            duration: 0.3,
                            ease: "easeInOut"
                        }}
                        onClick={(e) => e.stopPropagation()}
                        drag="y"
                        dragConstraints={{ top: 0, bottom: 0 }}
                        dragElastic={0.7}
                        onDragStart={(_, info) => setDragStart(info.point.y)}
                        onDragEnd={(_, info) => {
                            if (info.point.y - dragStart > 50) {
                                onClose();
                            }
                        }}
                        style={{
                            height: 'calc(100vh-100px)' // Вычитаем высоту фиксированного заголовка
                            // Компенсируем fixed header
                            // Отступ внизу для полной прокрутки
                        }}
                    >   
                        
                        <div className="w-12 h-1.5 bg-gray-300/20 rounded-full mx-auto mb-4 " />
                        {children}
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}

export function CustomModalReactFriend({ isOpen, onClose, children }) {
    const [dragStart, setDragStart] = useState(0);

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    className="fixed inset-0 z-50 flex items-end justify-center sm:items-center"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    onClick={onClose}
                >
                    <motion.div
                        className="bg-black show_shadow rounded-t-[20px] sm:rounded-[20px] w-full sm:w-[600px] p-4 relative"
                        style={{ height: '40dvh' }}
                        initial={{ y: '100%' }}
                        animate={{ y: 0 }}
                        exit={{ y: '100%' }}
                        transition={{ 
                            duration: 0.3,
                            ease: "easeInOut"
                        }}
                        onClick={(e) => e.stopPropagation()}
                        drag="y"
                        dragConstraints={{ top: 0, bottom: 0 }}
                        dragElastic={0.7}
                        onDragStart={(_, info) => setDragStart(info.point.y)}
                        onDragEnd={(_, info) => {
                            if (info.point.y - dragStart > 50) {
                                onClose();
                            }
                        }}
                    >
                        <div className="w-12 h-1.5 bg-gray-300/20 rounded-full mx-auto mb-4" />
                        <div className="flex flex-col justify-center items-center space-y-2 mt-4">
                            {children}
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}

export function CustomModalReactWallet({ isOpen, onClose, children }) {
    const [dragStart, setDragStart] = useState(0);
    const modalRef = useRef(null);
    const [isClosing, setIsClosing] = useState(false);
    
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
            setIsClosing(false);
        } else {
            document.body.style.overflow = 'unset';
        }
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [isOpen]);

    const handleClose = () => {
        setIsClosing(true);
        setTimeout(onClose, 300); // Задержка для анимации
    };

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    className="fixed inset-0 z-40 flex items-end justify-center sm:items-center"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    
                    onClick={handleClose}
                > 
                    <motion.div
                        ref={modalRef}
                        className="bg-black show_shadow rounded-t-[20px] w-full sm:w-[600px] relative flex flex-col transition-transform duration-200 ease-out"
                        style={{ height: '60dvh' }}
                        initial={{ y: '100%' }}
                        animate={{ y: 0 }}
                        exit={{ y: '100%' }}
                        transition={{ duration: 0.3, ease: "easeInOut" }}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div 
                            className="sticky top-0 left-0 right-0 z-10 bg-black rounded-t-[20px] p-4"
                            onTouchStart={(e) => {
                                setDragStart(e.touches[0].clientY);
                                if (modalRef.current) {
                                    modalRef.current.style.transition = 'none';
                                }
                            }}
                            onTouchMove={(e) => {
                                if (!modalRef.current) return;
                                const deltaY = e.touches[0].clientY - dragStart;
                                if (deltaY > 0) {
                                    modalRef.current.style.transform = `translateY(${Math.min(deltaY * 0.5, 200)}px)`;
                                }
                            }}
                            onTouchEnd={(e) => {
                                if (!modalRef.current) return;
                                modalRef.current.style.transition = 'transform 0.2s ease-out';
                                const deltaY = e.changedTouches[0].clientY - dragStart;
                                if (deltaY > 50) {
                                    modalRef.current.style.transform = 'translateY(100%)';
                                    handleClose();
                                } else {
                                    modalRef.current.style.transform = '';
                                }
                            }}
                        >
                            <div className="w-12 h-1.5 bg-gray-300/20 rounded-full mx-auto" />
                        </div>
                        <div className="flex-1 overflow-y-auto px-4">
                            <div className="flex flex-col justify-center items-center space-y-2 mt-4">
                                {children}
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}

export function CustomModalReactWalletSmall({ isOpen, onClose, children }) {
    const [dragStart, setDragStart] = useState(0);

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    className="fixed inset-0 z-50 flex items-end justify-center sm:items-center"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    onClick={onClose}
                >
                    <motion.div
                        className="bg-black show_shadow rounded-t-[20px] sm:rounded-[20px] w-full sm:w-[600px] p-4 relative"
                        style={{ height: '35dvh' }}
                        initial={{ y: '100%' }}
                        animate={{ y: 0 }}
                        exit={{ y: '100%' }}
                        transition={{ duration: 0.3, ease: "easeInOut" }}
                        onClick={(e) => e.stopPropagation()}
                        drag="y"
                        dragConstraints={{ top: 0, bottom: 0 }}
                        dragElastic={0.7}
                        onDragStart={(_, info) => setDragStart(info.point.y)}
                        onDragEnd={(_, info) => {
                            if (info.point.y - dragStart > 50) {
                                onClose();
                            }
                        }}
                    >
                        <div className="w-12 h-1.5 bg-gray-300/20 rounded-full mx-auto mb-4" />
                        <div className="flex flex-col justify-center items-center space-y-2 mt-4">
                            {children}
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}

export function CustomModalReactNft({ isOpen, onClose, children }) {
    const [dragStart, setDragStart] = useState(0);

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    className="fixed inset-0 z-50 flex items-end justify-center sm:items-center"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    onClick={onClose}
                    style={{ height: '100dvh' }}
                >
                    <motion.div
                        className="bg-black show_shadow rounded-t-[20px] sm:rounded-[20px] w-full sm:w-[600px] p-4 relative"
                        initial={{ y: '100%' }}
                        animate={{ y: 0 }}
                        exit={{ y: '100%' }}
                        transition={{ duration: 0.3, ease: "easeInOut" }}
                        onClick={(e) => e.stopPropagation()}
                        drag="y"
                        dragConstraints={{ top: 0, bottom: 0 }}
                        dragElastic={0.7}
                        onDragStart={(_, info) => setDragStart(info.point.y)}
                        onDragEnd={(_, info) => {
                            if (info.point.y - dragStart > 50) {
                                onClose();
                            }
                        }}
                    >
                        <div className="w-12 h-1.5 bg-gray-300/20 rounded-full mx-auto mb-4" />
                        {children}
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}

export function CustomModalMintNft({ isOpen, onClose, children }) {
    const [dragStart, setDragStart] = useState(0);

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    className="fixed inset-0 mt-10 z-50 flex items-end justify-center sm:items-center"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    onClick={onClose}
                    style={{ height: '100dvh' }}
                >
                    <motion.div
                        className="bg-black show_shadow rounded-t-[20px] sm:rounded-[20px] w-full sm:w-[600px] p-4 relative"
                        initial={{ y: '100%' }}
                        animate={{ y: 0 }}
                        exit={{ y: '100%' }}
                        transition={{ duration: 0.3, ease: "easeInOut" }}
                        onClick={(e) => e.stopPropagation()}
                        drag="y"
                        dragConstraints={{ top: 0, bottom: 0 }}
                        dragElastic={0.7}
                        onDragStart={(_, info) => setDragStart(info.point.y)}
                        onDragEnd={(_, info) => {
                            if (info.point.y - dragStart > 50) {
                                onClose();
                            }
                        }}
                    >
                        <div className="w-12 h-1.5 bg-gray-300/20 rounded-full mx-auto mb-4" />
                       
                        {children}
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}

export function CustomModal({ isOpen, onClose, children }) {
    const [dragStart, setDragStart] = useState(0);

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    className="fixed inset-0 z-50 flex items-end justify-center sm:items-center"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    onClick={onClose}
                >
                    <motion.div
                        className="bg-black show_shadow rounded-t-[20px] sm:rounded-[20px] w-full sm:w-[600px] p-4 relative"
                        style={{ height: '85dvh' }}
                        initial={{ y: '100%' }}
                        animate={{ y: 0 }}
                        exit={{ y: '100%' }}
                        transition={{ duration: 0.3, ease: "easeInOut" }}
                        onClick={(e) => e.stopPropagation()}
                        drag="y"
                        dragConstraints={{ top: 0, bottom: 0 }}
                        dragElastic={0.7}
                        onDragStart={(_, info) => setDragStart(info.point.y)}
                        onDragEnd={(_, info) => {
                            if (info.point.y - dragStart > 50) {
                                onClose();
                            }
                        }}
                    >
                        <div className="w-12 h-1.5 bg-gray-300/20 rounded-full mx-auto mb-4" />
                        <div dangerouslySetInnerHTML={{ __html: children }} />
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}
export function CustomModalShowNft({ isOpen, onClose, children }) {
    const [dragStart, setDragStart] = useState(0);
    const modalRef = useRef(null);
    const [isClosing, setIsClosing] = useState(false);
    
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
            setIsClosing(false);
        } else {
            document.body.style.overflow = 'unset';
        }
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [isOpen]);

    const handleClose = () => {
        setIsClosing(true);
        setTimeout(onClose, 300); // Задержка для анимации
    };

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    className="fixed inset-0 z-30 flex items-end justify-center sm:items-center"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    
                    onClick={handleClose}
                    style={{ height: '100dvh' }}
                > 
                    <motion.div
                        ref={modalRef}
                        className="bg-black show_shadow rounded-t-[20px] w-full sm:w-[600px] relative flex flex-col transition-transform duration-200 ease-out"
                        style={{ maxHeight: '90dvh' }}
                        initial={{ y: '100%' }}
                        animate={{ y: 0 }}
                        exit={{ y: '100%'}}
                        
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div 
                            className="sticky top-0 left-0 right-0 z-10 bg-black rounded-t-[20px] p-4 cursor-grab active:cursor-grabbing"
                            onTouchStart={(e) => {
                                setDragStart(e.touches[0].clientY);
                                if (modalRef.current) {
                                    modalRef.current.style.transition = 'none';
                                }
                            }}
                            onTouchMove={(e) => {
                                if (!modalRef.current) return;
                                const deltaY = e.touches[0].clientY - dragStart;
                                if (deltaY > 0) {
                                    modalRef.current.style.transform = `translateY(${Math.min(deltaY * 0.5, 200)}px)`;
                                }
                            }}
                            onTouchEnd={(e) => {
                                if (!modalRef.current) return;
                                modalRef.current.style.transition = 'transform 0.2s ease-out';
                                const deltaY = e.changedTouches[0].clientY - dragStart;
                                if (deltaY > 50) {
                                    modalRef.current.style.transform = 'translateY(100%)';
                                    handleClose();
                                } else {
                                    modalRef.current.style.transform = '';
                                }
                            }}
                        >
                            <div className="w-12 h-1.5 bg-gray-300/20 rounded-full mx-auto" />
                        </div>
                        <div className="flex-1 overflow-y-auto px-4 pb-20">
                            {children}
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}


export function CustomModalTask({ isOpen, onClose, children }) {
    const [dragStart, setDragStart] = useState(0);

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    className="fixed inset-0 z-50 flex items-end justify-center sm:items-center"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    onClick={onClose}
                >
                    <motion.div
                        className="bg-black show_shadow rounded-t-[20px] sm:rounded-[20px] w-full sm:w-[600px] relative"
                        style={{ height: '85dvh' }}
                        initial={{ y: '100%' }}
                        animate={{ y: 0 }}
                        exit={{ y: '100%' }}
                        transition={{ duration: 0.3, ease: "easeInOut" }}
                        onClick={(e) => e.stopPropagation()}
                        drag="y"
                        dragConstraints={{ top: 0, bottom: 0 }}
                        dragElastic={0.7}
                        onDragStart={(_, info) => setDragStart(info.point.y)}
                        onDragEnd={(_, info) => {
                            if (info.point.y - dragStart > 50) {
                                onClose();
                            }
                        }}
                    >
                        <div className="w-12 h-1.5 mt-4 bg-gray-300/20 rounded-full mx-auto mb-4" />
                        {children}
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}

export function CustomModalDownloadApp({ isOpen, onClose, children }) {
    const [dragStart, setDragStart] = useState(0);
    const language = localStorage.getItem('language_code') || 'en';
    const content = Getcontext({ language: language, contextKey: 'element' });

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    className="fixed inset-0 z-50 flex items-end justify-center sm:items-center"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    onClick={onClose}
                >
                    <motion.div
                        className="bg-black show_shadow rounded-t-[20px] sm:rounded-[20px] w-full sm:w-[600px] p-4 relative"
                        style={{ height: '60dvh' }}
                        initial={{ y: '100%' }}
                        animate={{ y: 0 }}
                        exit={{ y: '100%' }}
                        transition={{ duration: 0.3, ease: "easeInOut" }}
                        onClick={(e) => e.stopPropagation()}
                        drag="y"
                        dragConstraints={{ top: 0, bottom: 0 }}
                        dragElastic={0.7}
                        onDragStart={(_, info) => setDragStart(info.point.y)}
                        onDragEnd={(_, info) => {
                            if (info.point.y - dragStart > 50) {
                                onClose();
                            }
                        }}
                    >
                        <div className="w-12 h-1.5 bg-gray-300/20 rounded-full mx-auto mb-4" />
                        <div className="fixed top-0 right-0 left-0">
                            <div>
                                <div 
                                    style={{borderRadius: '25px 25px 0 0'}}   
                                    className="mb-[50px] bg-gradient-to-br from-[#2e1065]/50 to-[#4c1d95]/50 flex justify-center bg-black rounded-lg p-4 text-white text-lg font-bold"
                                >
                                    {content.download_app}
                                </div>
                            </div>
                        </div>  
                        <div className="flex flex-col items-center justify-center h-full">
                            <a href="https://storage.yandexcloud.net/drivecoin/app-release.apk" target="_blank" rel="noopener noreferrer" 
                               className="flex items-center gap-2 bg-black hover:bg-gray-800 text-white p-2 rounded-full shadow-lg transition-all duration-300 w-64 justify-center">
                                <img src={require("../img/google-play-icon.png")} alt="Google Play" className="w-full max-w-[200px] h-auto" />
                            </a>
                            
                            <div className="relative mt-10">
                                <img src={require("../img/app-store-icon.png")} alt="App Store" className="w-full max-w-[200px] h-auto opacity-50" />
                                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                                    <span className="text-white font-bold text-lg">Coming soon</span>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}
export function CustomModalClaimTon({ isOpen, onClose, children }) {
    const [dragStart, setDragStart] = useState(0);

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    className="fixed inset-0 z-50 flex items-end justify-center sm:items-center"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    onClick={onClose}
                    style={{ height: '100dvh' }}
                >
                    <motion.div
                        className="bg-black show_shadow rounded-t-[20px] sm:rounded-[20px] w-full sm:w-[600px] p-4 relative"
                        initial={{ y: '100%' }}
                        animate={{ y: 0 }}
                        exit={{ y: '100%' }}
                        transition={{ duration: 0.3, ease: "easeInOut" }}
                        onClick={(e) => e.stopPropagation()}
                        drag="y"
                        dragConstraints={{ top: 0, bottom: 0 }}
                        dragElastic={0.7}
                        onDragStart={(_, info) => setDragStart(info.point.y)}
                        onDragEnd={(_, info) => {
                            if (info.point.y - dragStart > 50) {
                                onClose();
                            }
                        }}
                    >
                        <div className="w-12 h-1.5 bg-gray-300/20 rounded-full mx-auto mb-4" />
                        {children}
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}

export default CustomModalReact;
