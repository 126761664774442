import { Globe, ChevronRight, X, ArrowLeft } from 'lucide-react';
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "./ui/sheet.tsx";
import { useState, useContext } from 'react';
import { UserContext } from '../UserContext.js';
import { Card } from './ui/card.tsx';
import { Button } from './ui/button.tsx';
import contentComponent from './Getcontext.js';
import { CustomModalDownloadApp } from './CustomModalReact.js';
const element = contentComponent({language:localStorage.getItem('language_code') || 'en', contextKey:'element'});

const languages = [
  { code: 'en', name: 'English' },
  { code: 'ru', name: 'Русский' },
  // { code: 'zh', name: '中文' },
  // { code: 'hi', name: 'हिन्दी' },
  // { code: 'es', name: 'Español' },
  // { code: 'fr', name: 'Français' },
  // { code: 'ar', name: 'العربية' },
  // { code: 'bn', name: 'বাংলা' }, 
  // { code: 'pt', name: 'Português' },
  // { code: 'id', name: 'Bahasa Indonesia' },
  // { code: 'ur', name: 'اردو' },
  // { code: 'de', name: 'Deutsch' },
  // { code: 'ja', name: '日本語' },
  // { code: 'sw', name: 'Kiswahili' },
  // { code: 'mr', name: 'मराठी' },
  // { code: 'te', name: 'తెలుగు' },
  // { code: 'ta', name: 'தமிழ்' },
  // { code: 'tr', name: 'Türkçe' },
  // { code: 'ko', name: '한국어' },
  // { code: 'it', name: 'Italiano' },
];

const changeLanguage = (newLanguageCode) => {
  localStorage.setItem('language_code', newLanguageCode);
  console.log(`Language changed to: ${newLanguageCode}`);
  window.location.reload();
};



export default function Setting() {
  const [currentLang, setCurrentLang] = useState(localStorage.getItem('language_code') || 'en');
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const [isDownloadOpen, setIsDownloadOpen] = useState(false);
  const {user} = useContext(UserContext);
  const deleteUser = () => {
    
    fetch(`https://drivecoin.io/api/delete-user/${user.user.id}`,{method:'DELETE'})
    .then(response => response.json())
    .then(data => {
      console.log('User deleted:', data);
    })
    .catch(error => {
      console.error('Error deleting user:', error);
    });
  }
  return (
    <div className='min-h-[100dvh]  bg-gradient-to-b from-[#1a0b2e] to-[#0f0618] text-white p-2' style={{ position: 'relative' }}>
      <div className="absolute top-4 left-4">
        <Button
          variant="ghost"
          className="p-2"
          onClick={() => window.history.back()}
        >
          <ArrowLeft className="h-6 w-6 text-white" />
        </Button>
      </div>
      <div className="mx-auto px-4 pb-2">
        <div className="py-6">
          <h2 className="text-center text-2xl mb-2 mt-4">{element.setting}</h2>
          <p className="text-center text-sm mb-4 text-gray-400">{element.description_setting}</p>
        </div>

        <Card className="overflow-hidden  border-0 bg-gradient-to-br from-[#2e1065]/50 to-[#4c1d95]/50 shadow-[0_0_15px_rgba(139,92,246,0.15)]">
          <Sheet open={isSheetOpen} onOpenChange={setIsSheetOpen}>
            <SheetTrigger asChild>
              <Button

                variant="ghost"
                className="w-full justify-between rounded-none border-0 p-4 hover:bg-violet-900/20"
                onClick={() => setIsSheetOpen(true)}
              >
                <div className="flex items-center gap-3">
                  <div className="rounded-full bg-violet-900/20 p-2">
                    <Globe className="h-5 w-5 text-violet-400" />
                  </div>
                  <div className="text-left">
                    <h3 className="font-medium text-white">{element.language}</h3>
                    <p className="text-sm text-gray-400">
                      {languages.find(lang => lang.code === currentLang)?.name}
                    </p>
                  </div>
                  <ChevronRight className="ml-auto h-5 w-5 text-gray-400" />
                </div>
              </Button>
            </SheetTrigger>
            <SheetContent style={{boxShadow: '0px 0px 50px #7c3aed, 0px 0px 100px #7c3aed, 0px 0px 300px #4f46e5', borderTop: '2px solid #7c3aed'}}
              side="bottom"
              className="h-[80vh] w-full rounded-t-[20px] border-t-2 border-violet-900/20 bg-[#1a0b2e] px-0"


            >
              <SheetHeader className="px-6 text-left flex justify-between items-center">
                <SheetTitle className="text-xl font-bold text-white">
                  {element.select_language}
                </SheetTitle>
                <Button
                  variant="ghost"
                  className="p-2"
                  onClick={() => setIsSheetOpen(false)}
                >
                  <X className="h-6 w-6 text-white" />
                </Button>
              </SheetHeader>
              <div className="mt-6 overflow-y-auto px-4 " style={{ maxHeight: '60vh' }}>
                {languages.map((language) => (
                  <Button
                    key={language.code}

                    variant="ghost"
                    className={`w-full justify-start gap-3 rounded-none px-6 py-4 text-left ${
                      currentLang === language.code
                        ? 'bg-violet-900/20 text-violet-400'
                        : 'text-gray-300 hover:bg-violet-900/10 hover:text-violet-400'
                    }`}
                    onClick={() => {
                      setCurrentLang(language.code);
                      changeLanguage(language.code);
                      setIsSheetOpen(false);
                    }}
                  >
                    <span className="text-base font-medium">{language.name}</span>
                    {currentLang === language.code && (
                      <div className="ml-auto h-2 w-2 rounded-full bg-violet-400" />
                    )}
                  </Button>
                ))}
              </div>
            </SheetContent>
          </Sheet>
        </Card>
      </div>
     
      <CustomModalDownloadApp isOpen={isDownloadOpen} onClose={() => {setIsDownloadOpen(false)}}>
        
      </CustomModalDownloadApp>
      <div className="mx-auto px-4 mt-6">
        <button
          onClick={() => setIsDownloadOpen(true)}
          className="w-full p-4 rounded-lg bg-gradient-to-r from-violet-900/50 to-purple-900/50 hover:from-violet-800/50 hover:to-purple-800/50 border border-violet-500/10 shadow-lg hover:shadow-violet-500/20 transition-all duration-300 group"
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <div className="p-2 rounded-full bg-violet-500/20 group-hover:bg-violet-500/30 transition-colors duration-300">
                <svg 
                  className="w-5 h-5 text-violet-400" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={2} 
                    d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                  />
                </svg>
              </div>
              <div className="text-left">
                <h3 className="text-white font-medium group-hover:text-violet-300 transition-colors duration-300">
                  {element.downloads}
                </h3>
                <p className="text-sm text-gray-400 group-hover:text-violet-400 transition-colors duration-300">
                  {element.download_app}
                </p>
              </div>
            </div>
            <div className="text-gray-400 group-hover:text-violet-400 transition-colors duration-300">
              <svg 
                className="w-5 h-5 transform group-hover:translate-x-1 transition-transform duration-300" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={2} 
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </div>
          </div>
        </button>
      </div>
      <div className="mt-1 flex flex-col items-center">
        <button
          variant="ghost"
          className="w-full justify-between gap-3 rounded-none text-center text-gray-400"
          onClick={() => {
            window.open('https://drivecoin.io', '_blank');
          }}
        >
          <span className="text-base font-medium text-sm">{element.politics}</span>
        </button>
        <button onClick={() => {
          if (window.confirm(element.delete_account_confirm)) {
            deleteUser(); 
          }
        }}>


          <span className="text-base font-medium text-sm">{element.delete_account}</span>
        </button>
      </div>
    </div>
  );
}