import {useTonConnect} from "./useTonconnect.ts";
import  {UserContext}  from "../UserContext.js";
import { useContext } from "react";
import { Address } from "@ton/core";
import { promiseHooks } from "v8";


interface NftItem {
    collection?: {
        address: string;
    };
    [key: string]: any;
}

export default function useNft() {
    const {wallet, network} = useTonConnect();
    const {setNfts, ton_balance, setTon_balance, ton_adress_sbt, setTon_adress_sbt, user } = useContext(UserContext) as {setNfts: (nfts: any[]) => void, ton_balance: number, setTon_balance: (value: number) => void, ton_adress_sbt: string, setTon_adress_sbt: (value: string) => void, user: any};

    const getContractBalance = async (contractAddress: string) => {
        const apiUrl = network === 'Testnet' 
            ? `https://testnet.tonapi.io/v2/accounts/${contractAddress}` 
            : `https://tonapi.io/v2/accounts/${contractAddress}`;
        
        try {
            const response = await fetch(apiUrl, {
                headers: {
                    'Authorization': `Bearer AGHVJNG57RBRJAIAAAADQCFQIS4AUEOXRI6DYLIJVWS33MIESA3RENAUNTCE4G4YGH5T67Q`,
                    'Accept': 'application/json',
                }
            });
            
            const data = await response.json();
            const balance = data.balance; // Возвращает баланс в нановалюте (1 TON = 1_000_000_000 нано)
            const balanceInTon = (balance/1000000000).toFixed(2); // Округляем до 2 знаков после запятой
            console.log("Баланс контракта:" , `${contractAddress} - `  ,`${balanceInTon} TON`);
            return balanceInTon;
            
        } catch (error) {
            console.error("Ошибка при получении баланса контракта:", error);
            return 0;
        }
    }

    const fetchNfts = async () => {
        let link = '';
        if (network === 'Testnet') {
            link = `https://testnet.tonapi.io/v2/accounts/${wallet}/nfts`;
        } else {
            link = `https://tonapi.io/v2/accounts/${wallet}/nfts`;
        }
        
        try {
            const response = await fetch(link, {
                headers: {
                    'Authorization': `Bearer AGHVJNG57RBRJAIAAAADQCFQIS4AUEOXRI6DYLIJVWS33MIESA3RENAUNTCE4G4YGH5T67Q`,
                    'Accept': 'application/json',
                }
            });
            
            const data = await response.json();
           
            
            const allowedCollections = [
                "0:f70054bb417e1fe93b349651caccb10f36583b9990e33fa0e893cdf5d7a03a38",
            ];
            const allowedVaucher = [
                "0:83a67d0dcb1688f1442fe91aaf8e88c0c7cee875a56f0dd9f1c41eec3d04dc5f"                
            ];
            const allowedSbt = [
                "0:27f1b38925d6880ca29e75eeef9cd8c1e21c3434b9d34ce2caca03002df3025b"                
            ];

            const filteredCollectionNfts = data.nft_items.filter((nft: NftItem) => 
                allowedCollections.includes(nft.collection?.address)
            );
            
            const filteredVoucherNfts = data.nft_items.filter((nft: NftItem) => 
                allowedVaucher.includes(nft.collection?.address)
                
            );

            const filteredSbtNfts = data.nft_items.filter((nft: NftItem) => 
                allowedSbt.includes(nft.collection?.address)
            ).slice(0, 1);




            
            const processedCollectionNfts = filteredCollectionNfts.map(nft => ({
                ...nft,
                type: 'nft', 
                
            }));
       
            
            const processedVoucherNfts = filteredVoucherNfts.map(nft => ({
                ...nft,
                type: 'voucher'
            }));

            const processedSbtNfts = filteredSbtNfts.map(nft => ({
                ...nft,
                type: 'sbt'
            }));
            
            const allNfts = [...processedCollectionNfts, ...processedVoucherNfts, ...processedSbtNfts];
            
            const sortedNfts = allNfts.sort((a, b) => {
                const nameA = a.metadata?.name?.toLowerCase() || '';
                const nameB = b.metadata?.name?.toLowerCase() || '';
                return nameA.localeCompare(nameB);
            });

            const nftsWithBalances = [];
            for (const nft of sortedNfts) {
                if (nft.type === 'sbt') {
                    try {
                        await new Promise(resolve => setTimeout(resolve, 3000));
                        const balance = await getContractBalance(nft.address);
                        setTon_balance(Number(balance));
                        
                        if(ton_adress_sbt !== nft.address) {
                            setTon_adress_sbt(nft.address);
                           
                            try {
                               
                                const response = await fetch('https://drivecoin.io/api/device-operations', {
                                    method: 'POST',
                                    headers: {
                                      'Content-Type': 'application/json',
                                      'charset': 'utf-8'
                                    },
                                    body: JSON.stringify({
                                      operation: 'saveSbtAddress',
                                      user_id_tg: user.user.id,
                                      sbt_address: nft.address,
                            
                                    })
                                });
                                const data = await response.json();
                                console.log("data", data);
                            } catch (error) {
                                console.error("Ошибка при сохранении sbt_address:", error);
                            }

                        }

                        nftsWithBalances.push({
                            ...nft,
                            contractBalance: balance
                        });
                    } catch (error) {
                        console.error(`Ошибка при получении баланса для ${nft.address}:`, error);
                        nftsWithBalances.push({
                            ...nft,
                            contractBalance: "0.00"
                        });
                        
                        if (error.response?.status === 429) {
                            console.log("Превышен лимит запросов, ожидание...");
                            await new Promise(resolve => setTimeout(resolve, 5000));
                        }
                    }
                    
                } else {
                    // Для не-SBT токенов просто добавляем их без баланса
                    nftsWithBalances.push({
                        ...nft,
                        contractBalance: "0.00"
                    });
                }

            }

            setNfts(nftsWithBalances);
            return nftsWithBalances;
            
        } catch (error) {
            console.error("Ошибка при получении токенов:", error);
            return [];
        }
    }
 
    return { fetchNfts }; 
}
