import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const Onboarding = ({ onComplete }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [direction, setDirection] = useState(0);
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);

    const slides = [
        {
            title: "Получайте монеты за поездки на автомобиле",
            description: "",
            //  ..image: require('../img/onbording/1.png'),
            color: "from-purple-600 to-blue-500"
        },
        {
            title: "Участвуйте в соревнованиях и розыгрышах",
            description: "",
            // image: require('../img/onboarding2.png'),
            color: "from-pink-600 to-purple-500"
        },
        {
            title: "Вступайте в автоклубы и находите друзей по всему миру",
            description: "",
            // image: require('../img/onboarding3.png'),
            color: "from-blue-600 to-cyan-500"
        },
        {
            title: "Подключите автомобиль к Telegram",
            description: "",
            // image: require('../img/onboarding3.png'),
            color: "from-blue-600 to-cyan-500"
        }
        ,
        {
            title: "Скачайте приложение Drive Connect из App Store или Google Play и следуйте инструкциям",
            description: "",
            // image: require('../img/onboarding3.png'),
            color: "from-blue-600 to-cyan-500"
        }
    ];

    const handleSlideChange = (newSlide) => {
        setDirection(newSlide > currentSlide ? 1 : -1);
        setCurrentSlide(newSlide);
    };

    const handleTouchStart = (e) => {
        setTouchEnd(null);
        setTouchStart(e.targetTouches[0].clientX);
    };

    const handleTouchMove = (e) => {
        setTouchEnd(e.targetTouches[0].clientX);
    };

    const handleTouchEnd = () => {
        if (!touchStart || !touchEnd) return;
        
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > 50;
        const isRightSwipe = distance < -50;
        
        if (isLeftSwipe && currentSlide < slides.length - 1) {
            handleSlideChange(currentSlide + 1);
        }
        if (isRightSwipe && currentSlide > 0) {
            handleSlideChange(currentSlide - 1);
        }
    };

    return (
        <div 
            className="fixed inset-0 bg-black z-50"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
        >
            <AnimatePresence initial={false} custom={direction}>
                <motion.div
                    key={currentSlide}
                    custom={direction}
                    initial={{ x: direction > 0 ? '100%' : '-100%' }}
                    animate={{ x: 0 }}
                    exit={{ x: direction > 0 ? '-100%' : '100%' }}
                    transition={{ 
                        duration: 0.3,
                        ease: "easeInOut"
                    }}
                    className="h-full w-full absolute"
                >
                    <div className={`h-full w-full bg-gradient-to-b ${slides[currentSlide].color} p-8 flex flex-col justify-between`}>
                        {/* Верхняя часть */}
                        <div className="flex justify-end">
                            <button 
                                onClick={onComplete}
                                className="text-white/70 text-sm"
                            >
                                Пропустить
                            </button>
                        </div>

                        {/* Центральная часть */}
                        <div className="flex-1 flex flex-col items-center justify-center text-center">
                            {/* <img 
                                src={slides[currentSlide].image} 
                                alt={slides[currentSlide].title}
                                className="w-64 h-64 object-contain mb-8"
                            /> */}
                            <h2 className="text-2xl font-bold text-white mb-4">
                                {slides[currentSlide].title}
                            </h2>
                            <p className="text-white/80 text-2xl">
                                {slides[currentSlide].description}
                            </p>
                        </div>

                        {/* Нижняя часть */}
                        <div className="space-y-6">
                            {/* Индикаторы */}
                            <div className="flex justify-center gap-2">
                                {slides.map((_, index) => (
                                    <div
                                        key={index}
                                        className={`h-1 rounded-full transition-all ${
                                            currentSlide === index 
                                                ? 'w-8 bg-white' 
                                                : 'w-2 bg-white/40'
                                        }`}
                                    />
                                ))}
                            </div>

                            {/* Кнопка */}
                            {currentSlide === slides.length - 1 ? (
                                <button
                                    onClick={onComplete}
                                    className="w-full bg-white text-purple-600 py-4 rounded-full font-semibold"
                                >
                                    Перейти в App Store
                                </button>
                            ) : (
                                <button
                                    onClick={() => handleSlideChange(currentSlide + 1)}
                                    className="w-full bg-white/20 text-white py-4 rounded-full font-semibold"
                                >
                                    Далее
                                </button>
                            )}
                        </div>
                    </div>
                </motion.div>
            </AnimatePresence>
        </div>
    );
};

export default Onboarding; 