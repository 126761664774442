import React, { useRef, useEffect } from 'react';

export function BoosterBottomSheet({ isOpen, onClose, children }) {
    const sheetRef = useRef(null);
    const startY = useRef(0);
    const currentY = useRef(0);

    useEffect(() => {
        const sheet = sheetRef.current;
        if (!sheet) return;

        const handleTouchStart = (e) => {
            startY.current = e.touches[0].clientY;
            currentY.current = e.touches[0].clientY;
            sheet.style.transition = 'none';
        };

        const handleTouchMove = (e) => {
            e.preventDefault();
            currentY.current = e.touches[0].clientY;
            const deltaY = currentY.current - startY.current;
            
            if (deltaY > 0) {
                const resistance = 0.5;
                const transform = deltaY * resistance;
                sheet.style.transform = `translateY(${transform}px)`;
            }
        };

        const handleTouchEnd = () => {
            const deltaY = currentY.current - startY.current;
            sheet.style.transition = 'transform 0.2s ease-out';
            
            if (deltaY > 50) {
                sheet.style.transform = 'translateY(100%)';
                setTimeout(() => {
                    onClose();
                    sheet.style.transform = '';
                }, 200);
            } else {
                sheet.style.transform = 'translateY(0)';
            }
        };

        sheet.addEventListener('touchstart', handleTouchStart);
        sheet.addEventListener('touchmove', handleTouchMove, { passive: false });
        sheet.addEventListener('touchend', handleTouchEnd);

        return () => {
            sheet.removeEventListener('touchstart', handleTouchStart);
            sheet.removeEventListener('touchmove', handleTouchMove);
            sheet.removeEventListener('touchend', handleTouchEnd);
        };
    }, [onClose]);

    return (
        <>
            {isOpen && (
                <div
                    className="fixed inset-0 z-[45] transition-opacity"
                    onClick={onClose}
                />
            )}
            
            <div
                ref={sheetRef}
                style={{height:'75dvh'}}
                className={`bg-black fixed left-0 right-0 bottom-0 z-[45] transform transition-transform duration-200 ease-out ${
                    isOpen ? 'translate-y-0 show_shadow' : 'translate-y-full'
                }`}
            >
                <div className="bg-black rounded-t-[20px] shadow-xl max-h-[85vh] overflow-y-auto overscroll-contain">
                    <div 
                        className="w-12 h-1.5 bg-gray-500/30 rounded-full mx-auto my-3 touch-none"
                        style={{ touchAction: 'none' }}
                    />
                    <div className="overscroll-contain">
                        {children}
                    </div>
                </div>
            </div>
        </>
    );
} 