import React, { useEffect, useState, useContext, useRef } from 'react';
import {UserContext} from '../UserContext.js';
import { CustomModalDownloadApp } from './CustomModalReact.js';
import { createStars } from './ConfettiEffect';
import Getcontext from './Getcontext.js';
import { CustomBottomSheet } from './CustomBottomSheet.js';

function BreathingButton() {
  const { distance, setDistance, total_distance, setTotal_distance, user, device_id } = useContext(UserContext);
  const [isScrolling, setIsScrolling] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [dailyBonusAvailable, setDailyBonusAvailable] = useState(false);
  const [currentBonusAmount, setCurrentBonusAmount] = useState(10);
  const [isDailyBonusProcessing, setIsDailyBonusProcessing] = useState(false);
  const [isBoostSheetOpen, setIsBoostSheetOpen] = useState(false);
  const [isRendered, setIsRendered] = useState(false);
  const language = localStorage.getItem('language_code') || 'en';
  const content = Getcontext({ language: language, contextKey: 'button_main' });
  const bottomSheetRef = useRef(null);

  const BASE_BONUS = 10;
  const MAX_BONUS = 100;
  const BONUS_INCREMENT = 10;

  useEffect(() => {
    let scrollTimeout;
    
    const checkDailyBonus = () => {
      // Если бонус уже был получен сегодня, не показываем окно
      const lastBonusDate = localStorage.getItem('lastDailyBonus');
      const lastBonusAmount = parseInt(localStorage.getItem('lastBonusAmount') || BASE_BONUS.toString());
      
      const today = new Date().setHours(0, 0, 0, 0);
      const lastDate = lastBonusDate ? new Date(lastBonusDate).setHours(0, 0, 0, 0) : null;

      // Проверяем, не был ли уже получен бонус сегодня
      if (lastDate === today) {
        setDailyBonusAvailable(false);
        setIsBoostSheetOpen(false);
        return;
      }
      
      if (!lastBonusDate) {
        // Первый запуск приложения
        setDailyBonusAvailable(true);
        setCurrentBonusAmount(BASE_BONUS);
        !isRendered && setIsBoostSheetOpen(true);
        return;
      }

      if (today > lastDate) {
        const nextBonus = today - lastDate <= 86400000 
          ? Math.min(lastBonusAmount + BONUS_INCREMENT, MAX_BONUS)
          : BASE_BONUS;
        
        setCurrentBonusAmount(nextBonus);
        setDailyBonusAvailable(true);
        !isRendered && setIsBoostSheetOpen(true);
      }
    };

    // Проверяем бонус при монтировании и после его получения
    checkDailyBonus();

    // Обработчик скролла
    const handleScroll = () => {
      setIsScrolling(true);
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
        setIsScrolling(false);
      }, 1000);
    };

    window.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isRendered]); // Добавляем isRendered в зависимости

  // Отдельный useEffect для мониторинга получения бонуса
  useEffect(() => {
    if (!dailyBonusAvailable) {
      setIsBoostSheetOpen(false);
    }
  }, [dailyBonusAvailable]);

  useEffect(() => {
    // Проверяем, не было ли уже показано окно в текущей сессии
    if (!isRendered && dailyBonusAvailable) {
      const lastBonusDate = localStorage.getItem('lastDailyBonus');
      const today = new Date().setHours(0, 0, 0, 0);
      
      if (!lastBonusDate || new Date(lastBonusDate).setHours(0, 0, 0, 0) < today) {
        setIsBoostSheetOpen(true);
        setIsRendered(true);
      }
    }
  }, [dailyBonusAvailable, isRendered]);

  async function updateDistance() {

    if (processing) return;
    
    setProcessing(true);
    const previousDistance = distance;
    const previousTotalDistance = total_distance;

    try {
      const response = await fetch(`https://drivecoin.io/api/update-distance/${user.user.id}`, {
        method: 'PUT',
      });
      
      const results = await response.json();
      
      if (response.status >= 400) {
        throw new Error(results.error || content.update_error);
      }
      
      setDistance(0);
      animateValue(total_distance, total_distance + distance, 1000);
      createStars();
      
      try {
        const claims = JSON.parse(localStorage.getItem('claims') || '[]');
        claims.push({
          date: new Date().toISOString(),
          distance: parseFloat(distance),
          type: 'claim'
        });
        localStorage.setItem('claims', JSON.stringify(claims));
        window.dispatchEvent(new Event('claimUpdated'));
      } catch (error) {
        console.error('Ошибка сохранения статистики:', error);
      }
      
    } catch (error) {
      console.error(content.update_error, error);
      setDistance(previousDistance);
      setTotal_distance(previousTotalDistance);
      alert(content.update_error);
      return false;
    } finally {
      setProcessing(false);
    }
  }

  const animateValue = (start, end, duration) => {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      const current = Number((progress * (end - start) + start).toFixed(2));
      setTotal_distance(current);
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  };

  const claimDailyBonus = async () => {
    if (isDailyBonusProcessing) return;
    
    setIsDailyBonusProcessing(true);

    try {
      const response = await fetch('https://drivecoin.io/api/device-operations', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          operation: 'claimDailyBonus',
          user_id_tg: user.user.id,
          bonus_amount: currentBonusAmount
        })
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Ошибка сервера');
      }

      const results = await response.json();
      
      // Программно закрываем окно
      if (bottomSheetRef.current?.close) {
        bottomSheetRef.current.close();
      }
      
      setIsBoostSheetOpen(false);
      setDailyBonusAvailable(false);
      
      setTimeout(() => {
        setTotal_distance(total_distance + currentBonusAmount);
        createStars();

        try {
          const claims = JSON.parse(localStorage.getItem('claims') || '[]');
          claims.push({
            date: new Date().toISOString(),
            distance: currentBonusAmount,
            type: 'daily_bonus'
          });
          localStorage.setItem('claims', JSON.stringify(claims));
          window.dispatchEvent(new Event('claimUpdated'));
        } catch (error) {
          console.error('Ошибка сохранения статистики:', error);
        }

        localStorage.setItem('lastDailyBonus', new Date().toISOString());
        localStorage.setItem('lastBonusAmount', currentBonusAmount.toString());
      }, 300);

    } catch (error) {
      console.error('Ошибка получения бонуса:', error);
      alert(error.message);
    } finally {
      setIsDailyBonusProcessing(false);
    }
  };

  const resetDailyBonus = () => {
    localStorage.removeItem('lastDailyBonus');
    localStorage.removeItem('lastBonusAmount');
    setDailyBonusAvailable(true);
    setCurrentBonusAmount(BASE_BONUS);
    setIsRendered(false);
    setIsBoostSheetOpen(true);
  };

  const renderBonusContent = () => {
    return (
      <div className="p-6 pb-8 text-center">
        <h3 className="text-xl font-medium text-white mb-4">Ежедневный бонус</h3>
        
        <div className="space-y-4 mb-6">
          <div className="bg-gradient-to-r from-[#2e1065]/50 to-[#4c1d95]/50 p-4 rounded-lg">
            <div className="flex items-center justify-center gap-2 mb-4">
              <img src={require('../img/dp.png')} alt="dp" className='w-8 h-8' />
              <span className="text-2xl text-white font-bold">{currentBonusAmount}</span>
            </div>
            
            <p className="text-gray-400 text-sm mb-4">
              Заходите в приложение каждый день, чтобы получать больше бонусов:             
            </p>
            

            <div className="grid grid-cols-5 gap-2 mb-4">
              {[10, 20, 30, 40, 50, 60, 70, 80, 90, 100].slice(0, 5).map((bonus, index) => (
                <div 
                  key={index}
                  className={`p-2 rounded ${
                    bonus <= currentBonusAmount 
                      ? 'bg-indigo-600 text-white' 
                      : 'bg-gray-700/50 text-gray-400'
                  }`}
                >
                  {bonus}
                </div>
              ))}
            </div>
            <div className="grid grid-cols-5 gap-2">
              {[10, 20, 30, 40, 50, 60, 70, 80, 90, 100].slice(5).map((bonus, index) => (
                <div 
                  key={index + 5}
                  className={`p-2 rounded ${
                    bonus <= currentBonusAmount 
                      ? 'bg-indigo-600 text-white' 
                      : 'bg-gray-700/50 text-gray-400'
                  }`}
                >
                  {bonus}
                </div>
              ))}
            </div>
          </div>

          <div className="text-sm text-gray-400">
            
            <p className="mb-2">🏆 Максимальный бонус: 100 dp </p>
            <p>⚠️ При пропуске дня бонус сбрасывается </p>
          </div>
        </div>

        <button
          className={` button-custom rounded-3xl bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] shadow-lg p-2.5 shadow-indigo-500/50 ${
            isDailyBonusProcessing ? 'opacity-75' : 'hover:shadow-lg hover:shadow-indigo-500/50 transition-shadow'
          }`}
          onClick={claimDailyBonus}
          disabled={isDailyBonusProcessing}
        >
          <div className="flex items-center justify-center gap-2">
            {isDailyBonusProcessing ? (
              <span>Получение бонуса...</span>
            ) : (
              <>
                <span>Забрать</span>
                <img src={require('../img/dp.png')} alt="dp" className="w-4 h-4" />
                <span>{currentBonusAmount} dp</span>
              </>
            )}
          </div>
        </button>

       
     
      </div>
    );
  };
  const openDriveCoinApp = () => {
    try {
        if (window.Telegram && window.Telegram.WebApp ) {
          
          if (window.Telegram.WebApp.platform === 'android') {
            window.Telegram.WebApp.openLink('https://wintext.ru/redirect.html', {
                try_instant_view: false
            });
          } else {
            window.Telegram.WebApp.openLink('https://wintext.ru/redirect_ios.html', {
                try_instant_view: false
            });
          }
       
       
       
       
            
        } else {
           alert('Telegram WebApp API не доступен');
        }
    } catch (error) {
        alert('Ошибка при открытии приложения:', error);
    }
};
  return (<>
     
    {(distance > 0 || device_id) ? (
      <button
        className={`${distance ? "bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] shadow-lg " : "opacity-80 bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] shadow-lg "} button-custom mb-4 rounded-3xl p-2.5 shadow-indigo-500/50`}
        disabled={processing}
        onClick={() => {
          if (distance) {
            updateDistance();
          } else {
            // Открываем нативное приложение
            openDriveCoinApp();
          }
        }}
      >
        {processing ? content.processing : (distance ? `${content.claim} ${distance} ${content.km}` : content.waiting_points)}
      </button>
      
    ) : (
      <button
        className="button-custom mb-4 rounded-3xl bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] shadow-lg p-2.5 shadow-indigo-500/50"
        onClick={() => setIsOpen(true)}
      >
        {content.connect_elm}
      </button>
    )}

    {/* Добавляем тестовую кнопку только в режиме разработки */}
    
     
    

    <CustomBottomSheet 
      ref={bottomSheetRef}
      isOpen={isBoostSheetOpen && dailyBonusAvailable} 
      onClose={() => {
        setIsBoostSheetOpen(false);
        setIsRendered(true);
        setDailyBonusAvailable(false);
      }}
    >
      {dailyBonusAvailable && renderBonusContent()}
    </CustomBottomSheet>

    <CustomModalDownloadApp isOpen={isOpen} onClose={() => setIsOpen(false)} />
    
  </>);
}

export default BreathingButton;
