import React  from 'react';
import ListGroupItem from './ListGroupItem.js';



import taskData from './context/task.json';
import contentComponent from './Getcontext.js';

const language = localStorage.getItem('language_code') || 'en';
const cards = taskData.cards[language];
const element = contentComponent({language:language, contextKey:'element'});
function Task() {
  
  return <div className='min-h-[100dvh] bg-gradient-to-b from-[#1a0b2e] to-[#0f0618] text-white p-4 pb-20' style={{ position: 'relative' }}>
    
    
    {/* <div className="globe"></div>; */}

    {/* <img src={require('../img/task4.webp')} alt="task" className='rounded-lg mx-auto mb-4 shadow-lg from-[#1a0b2e] to-[#0f0618] w-full' /> */}
    <div className='text-center text-2xl mb-2 mt-4 flex items-center justify-center gap-2 '>
    {element.task}
               
           </div>
   
    <ListGroupItem items={cards} />
    
    
    </div>
    
  
  
  
  
  
  
  
  
}

export default Task;
