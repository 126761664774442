import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft, Gauge } from 'lucide-react';
import Getcontext from './Getcontext.js';
import { User } from 'lucide-react';
import  {UserContext } from '../UserContext.js'; 

const formatNumber = (num) => {
    if (num >= 1000000) {
        return (num / 1000000).toFixed(1) + 'M';
    } else if (num >= 1000) {
        return (num / 1000).toFixed(1) + 'K';
    }
    return num.toString();
};

const ClubMembers = () => {
    const { clubId } = useParams();
    const navigate = useNavigate();
    const [clubData, setClubData] = useState(null);
    const [loading, setLoading] = useState(true);
    const language = localStorage.getItem('language_code') || 'en';
    const content = Getcontext({ language: language, contextKey: 'clubs' });
    const { user, setUser } = useContext(UserContext); 

    useEffect(() => {
        const fetchClubMembers = async () => {
            if (!user?.user?.id) return;

            try {
                const response = await fetch('https://drivecoin.io/api/device-operations', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        operation: 'getClubMembers',
                        clubId: clubId
                    })
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch club members');
                }
                const data = await response.json();
                
                setClubData(data);
            } catch (error) {
                console.error('Error:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchClubMembers();
    }, [clubId, user?.user?.id]);

    return (
        <div className="min-h-screen bg-[#1a0b2e] text-white p-4">
            {/* Шапка с кнопкой назад */}
            <div className="flex items-center mb-4">
                <button 
                    onClick={() => navigate(-1)} 
                    className="p-2 hover:bg-[#2a1b3e] rounded-full transition-colors"
                >
                    <ArrowLeft className="w-6 h-6" />
                </button>
                <h1 className="ml-1 text-md font-md">
                    {loading ? 'Загрузка...' : "Клубы" }
                  
                </h1>
            </div>
            <>
        <div className="mb-6 h-[180px] overflow-hidden rounded-2xl bg-gradient-to-br from-[#2e1065] to-[#4c1d95] p-3 shadow-[0_0_15px_rgba(139,92,246,0.3)]">
            <div className="mb-4 flex items-center justify-between">
                  
        
                <h3 className="text-lg font-medium text-gray-200">{loading ? 'Загрузка...' : clubData?.name } </h3> 
                {loading ? 'Загрузка...' :   <img src={require(`../img/clubs/${clubData.photo_url}`)} className="w-10 h-10 object-cover rounded-lg flex-shrink-0" />  }
        
            </div>
        


        <div className="flex flex-col gap-0">
            {/* Ваше место и Всего участников в одной строке */}
            <div className="flex justify-between">
                {/* Ваше место */}
                <div>
                    <div className="text-sm text-gray-400">Ваша позиция</div>
                    <div className="text-2xl font-bold text-white">
                        {user?.user?.id && clubData?.members && 
                         clubData.members.some(member => 
                            parseFloat(member.user_id_tg) === parseFloat(user.user.id)
                         ) ? (
                            `#${clubData.members.findIndex(member => 
                                parseFloat(member.user_id_tg) === parseFloat(user.user.id)
                            ) + 1}`
                        ) : (
                            '-'

                        )}
                    </div>
                </div>

                {/* Всего участников */}
                <div>
                    <div className="text-sm text-gray-400">Всего участников</div>
                    <div className="text-2xl font-bold text-white">
                        {clubData?.members?.length || 0}
                    </div>
                </div>
            </div>

            {/* Общий пробег клуба */}
            <div>
                <div className="text-sm text-gray-400">Всего монет</div>
                <div className="text-2xl font-bold text-white flex items-center">
                    {formatNumber(
                        clubData?.members?.reduce((sum, member) => 
                            sum + parseFloat(member.rating || 0), 0
                        ) || 0
                    )}
                    <span className="text-sm text-gray-400 ml-1">
                        <img src={require(`../img/dp.png`)} alt="dp" className='w-4 h-4 inline-block ml-1' />
                    </span>
                </div>
            </div>
        </div>

        <div className="mt-4 text-sm text-gray-400">
            {clubData?.description}
        </div>
      </div>
</>
            {loading ? (
                <div className="flex justify-center items-center">
                    <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-violet-500"></div>
                </div>
            ) : (
                 
                <div className="space-y-2">
                     
                    {clubData?.members?.map((member, index) => (
                        <div key={member.id} className="flex items-center bg-[#2a1b3e] p-4 rounded-lg relative">
                            <div className="absolute left-0 top-0 w-8 h-8 flex items-center justify-center bg-violet-500/20 rounded-tl-lg rounded-br-lg text-violet-400 text-sm">
                              
                                {index < 3 ? (
                                  <img 
                                    src={require(`../img/clubs/${index === 0 ? 'gold' : index === 1 ? 'silver' : 'bronze'}.png`)}
                                    alt={index === 0 ? 'gold' : index === 1 ? 'silver' : 'bronze'}
                                    className="absolute left-1/2 top-1/2 w-4 h-4 transform -translate-x-1/2 -translate-y-1/2"
                                  />
                                ) : (
                                  `#${index + 1}`
                                )}
                            </div>
                            {member.photo_url ? (
                                <img 
                                    src={member.photo_url} 
                                    className="w-8 h-8 rounded-full object-cover ml-6 mr-2 "
                                />
                            ) : (
                                <div className="rounded-full bg-gradient-to-r mr-2 ml-6 object-cover from-[#f97316] to-[#f59e0b]">
                                    <User className="w-8 h-8 object-cover text-white rounded-full" />
                                </div>
                            )}
                            <div className="ml-2">
                                <p className="text-medium">{member.username !== "No" ? member.username : member.first_name}</p>
                                {/* <p className="text-sm text-gray-400">
                                    {content.rating}: {member.rating || 0} {content.km}
                                </p> */}    
                            </div>
                            <div className='text-md text-white ml-auto pr-2 flex items-end'> 
                                {member.rating ? parseFloat(member.rating).toFixed(0) : 0}
                                <span className='text-sm text-gray-400 ml-1 flex items-end'>
                                    <img src={require(`../img/dp.png`)} alt="dp" className='w-4 h-4 mb-[4px] ml-1' />
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ClubMembers; 