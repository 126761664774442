import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from 'recharts';

const DailyStatsChart = () => {
    const [data, setData] = useState([]);
    const [period, setPeriod] = useState('week'); // 'week' или 'month'

    const getDateString = (date) => {
        return `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(2, '0')}`;
    };

    const getStatsData = (selectedPeriod) => {
        try {
            const claims = JSON.parse(localStorage.getItem('claims') || '[]');
            const today = new Date();
            const daysToShow = selectedPeriod === 'week' ? 7 : 30;
            
            // Создаем массив дат за выбранный период
            const dates = Array.from({ length: daysToShow }, (_, i) => {
                const date = new Date();
                date.setDate(today.getDate() - (daysToShow - 1 - i));
                return {
                    date: getDateString(date),
                    distance: 0,
                    referralDistance: 0
                };
            });

            // Заполняем данные из localStorage, строго разделяя типы
            claims.forEach(claim => {
                const claimDate = getDateString(new Date(claim.date));
                const dateEntry = dates.find(d => d.date === claimDate);
                
                if (dateEntry) {
                    const distance = parseFloat(claim.distance) || 0;
                    
                    // Строгая проверка типа
                    if (claim.type && claim.type === 'referral') {
                        dateEntry.referralDistance += distance;
                    } else {
                        dateEntry.distance += distance;
                    }
                }
            });

            return dates;
        } catch (error) {
            console.error('Ошибка обработки данных статистики:', error);
            return [];
        }
    };

    // Выносим обработку данных в отдельную функцию для избежания блокировки UI
    const processStatsData = (selectedPeriod) => {
        try {
            return getStatsData(selectedPeriod);
        } catch (error) {
            console.error('Ошибка обработки статистики:', error);
            return [];
        }
    };

    useEffect(() => {
        const updateStats = () => {
            // Используем setTimeout для асинхронного обновления
            setTimeout(() => {
                const statsData = processStatsData(period);
                setData(statsData);
            }, 0);
        };

        updateStats();

        window.addEventListener('storage', updateStats);
        window.addEventListener('claimUpdated', updateStats);
        
        return () => {
            window.removeEventListener('storage', updateStats);
            window.removeEventListener('claimUpdated', updateStats);
        };
    }, [period]);

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="bg-[#1f1f1f] p-2 rounded-lg border border-[#3f3f3f]">
                    <p className="text-white">{`${label}`}</p>
                    {payload.map((entry, index) => (
                        <p key={index} className="text-violet-400">
                            {entry.dataKey === 'distance' ? 'Клеймы: ' : 'Рефералы: '}
                            {`${entry.value.toFixed(1)} dp`}
                        </p>
                    ))}
                </div>
            );
        }
        return null;
    };

    // Функция для сохранения нового клейма (вызывается при успешном клейме)
    const addNewClaim = (distance) => {
        const claims = JSON.parse(localStorage.getItem('claims') || '[]');
        claims.push({
            date: new Date().toISOString(),
            distance: distance
        });
        localStorage.setItem('claims', JSON.stringify(claims));
        
        // Обновляем график
        const statsData = getStatsData(period);
        setData(statsData);
    };

    return (
        <div className="w-full h-[180px] p-3 bg-gradient-to-br from-[#2e1065]/50 to-[#4c1d95]/50 rounded-lg">
            <div className="flex justify-between items-center mb-2">
                <h2 className="text-white text-sm">Статистика</h2>
                <div className="flex gap-1">
                    <button
                        onClick={() => setPeriod('week')}
                        className={`px-3 py-0.5 rounded-full text-xs ${
                            period === 'week'
                                ? 'bg-violet-600 text-white'
                                : 'bg-[#1f1f1f] text-gray-400'
                        }`}
                    >
                        Неделя
                    </button>
                    <button
                        onClick={() => setPeriod('month')}
                        className={`px-3 py-0.5 rounded-full text-xs ${
                            period === 'month'
                                ? 'bg-violet-600 text-white'
                                : 'bg-[#1f1f1f] text-gray-400'
                        }`}
                    >
                        Месяц
                    </button>
                </div>
            </div>
            <ResponsiveContainer width="100%" height="85%">
                <BarChart data={data} margin={{ top: 5, right: 5, left: -20, bottom: 0 }}>
                    <XAxis 
                        dataKey="date" 
                        tick={{ fill: '#9ca3af', fontSize: 10 }} 
                        axisLine={{ stroke: '#4b5563' }}
                    />
                    <YAxis 
                        tick={{ fill: '#9ca3af', fontSize: 10 }} 
                        axisLine={{ stroke: '#4b5563' }}
                        unit=" dp"
                        tickFormatter={(value) => {
                            if (value >= 1000) {
                                return (value / 1000).toFixed(1) + 'k';
                            }
                            return value;
                        }}
                        width={45} // Увеличиваем ширину оси для размещения значений
                    />
                    <Tooltip content={<CustomTooltip />} cursor={false} />
                    <Bar 
                        dataKey="distance" 
                        name="Клеймы"
                        fill="#8b5cf6"
                        radius={[3, 3, 0, 0]}
                        onMouseOver={() => {}}
                        onMouseLeave={() => {}}
                        activeBar={false}
                    />
                    <Bar 
                        dataKey="referralDistance" 
                        name="Рефералы"
                        fill="#f59e0b"
                        radius={[3, 3, 0, 0]}
                        onMouseOver={() => {}}
                        onMouseLeave={() => {}}
                        activeBar={false}
                    />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default DailyStatsChart; 