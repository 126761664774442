import React, { useState, useEffect, useContext } from "react";

import contentComponent from "./Getcontext.js";
import {CustomModalReactWallet, CustomModalClaimTon , CustomModalMintNft, CustomModalReactWalletSmall, CustomModalShowNft} from "./CustomModalReact.js";
import {BatteryCharging } from 'lucide-react';
import { ToastContainer, toast } from "react-toastify";
import { UserContext } from "../UserContext.js";
import {useTonConnect} from "../Hook/useTonconnect.ts";
import useNft from "../Hook/useNft.ts";
import {useContractData} from "../Hook/useContractNft.ts";
import {useContractMint} from "../Hook/useContractNft.ts";
import {Address, fromNano} from "@ton/core";
import Getcontext from "./Getcontext.js";
import Marketplace from './Marketplace';
import GetTonModal from './GetTonModal';
import SwapTonInput from './SwapTonInput';

import ProkaChka from './ProkaChka';



const WalletConnectButton = () => {
    const language = localStorage.getItem('language_code') || 'en';
    const content = Getcontext({ language: language, contextKey: 'wallet' }); 
    
    const {sender, connected, wallet, network, tonAddress ,  tonConnectUI, tonConnectModal} = useTonConnect(); 
    const {nfts, user_id_tg, setNfts,ton_balance, ton_adress_sbt_level_1, ton_adress_sbt_level_2, ton_adress_sbt_level_3, total_distance, walletAddress, setTotal_distance,  setWalletAddress, user , setIsLoading, isLoading, Tabclick, createStars, refParent1, refParent2, refParent3} = useContext(UserContext);
    const {fetchNfts} = useNft();
    
    const [showModal, setShowModal] = useState(false);
    const [showModalMintMenu, setShowModalMintMenu] = useState(false);
    const [showModalMintVoucher, setShowModalMintVoucher] = useState(false);
    const [showModalMintSbt, setShowModalMintSbt] = useState(false);
    const [showModalMint, setShowModalMint] = useState(false);
    const [selectedNftIndex, setSelectedNftIndex] = useState(false);
    
    const [nft_content, setNftContent] = useState("");
    const [nftUpdate, setNftUpdate] = useState(nfts);
    const [boostValue, setBoostValue] = useState('0');
    const [isExpanded, setIsExpanded] = useState(false);
    const [boostValueVaucher, setBoostValueVaucher] = useState(250);
    const [showModalChooseVoucher, setShowModalChooseVoucher] = useState(false);
    const [showModalChooseNft, setShowModalChooseNft] = useState(false);
    const [nftActivateVoucher, setNftActivateVoucher] = useState();
    const [mathboostValue, setMathboostValue] = useState(0);
    const [showModalClaimTon, setShowModalClaimTon] = useState(false);
    const [nftHash, setNftHash] = useState();

    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);
    
    // минимальное расстояние для свайпа (в пикселях)
    const minSwipeDistance = 50;

    let {next_item, nextItemIndex, refetch} = useContractData();
    
    let {mint,  sendBoost, sendNewMeta, sendMintVoucher, sendActivateVoucher, mintsbt} = useContractMint();
    
    const handleCloseMintMenu = () => setShowModalMintMenu(false);
    const handleCloseChooseVoucher = () => setShowModalChooseVoucher(false);
    const handleCloseChooseNft = () => setShowModalChooseNft(false);
    const handleCloseMintVoucher = () => setShowModalMintVoucher(false);
    const handleClose = () => setShowModal(false);
    const handleCloseMint = () => setShowModalMint(false);
    const handleCloseNft = () => setSelectedNftIndex(false);
    const handleCloseMintSbt = () => setShowModalMintSbt(false);
    const handleCloseClaimTon = () => setShowModalClaimTon(false);
    const functionsetShowModalChooseVoucher = (nft_address) => {
        setShowModalChooseVoucher(true);
        setNftActivateVoucher(nft_address);
    }
    const functionsetShowModalChooseNft = (nft_address) => {
        setShowModalChooseNft(true);
        setNftActivateVoucher(nft_address);
    }

    const fetchDataWallet = async (tonAddress) => {
        try {
            if (!user?.user?.id) {
                console.log('User ID не доступен');
                return;
            }
            
            const response = await fetch(`https://drivecoin.io/api/data/wallet/${user.user.id}/${tonAddress}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            
            const results = await response.json();
            console.log('Ответ сервера:', results);
            
            if(results.success){
                setWalletAddress(tonAddress);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    

    useEffect(() => {
        setNftHash(nfts);
        console.log('изменился nftHash:', nftHash);
    }, [nfts]);

    useEffect(() => {
        if(tonAddress && user?.user?.id){
            if(tonAddress !== walletAddress){
                fetchDataWallet(tonAddress);
            }
        }
    }, [tonAddress, user]);



    const frendlyAddress = (address) => {
        return Address.parse(address).toString({
            testOnly: network === 'Testnet',
            bounceable: true,
            urlSafe: true
        });
    }


    const truncateAddress = (address) => {
        if (!address) return '';
        try {
            // Преобразуем в дружественный формат
            if (typeof address === 'string') {
                const friendlyAddress = Address.parse(address).toString({
                    testOnly: network === 'Testnet',
                    bounceable: true,
                    urlSafe: true
                });
                return `${friendlyAddress.slice(0, 4)}...${friendlyAddress.slice(-4)}`;
            }
            // Для объекта Address
            if (address instanceof Address) {
                const friendlyAddress = address.toString({
                    testOnly: network === 'Testnet',
                    bounceable: true,
                    urlSafe: true
                });
                return `${friendlyAddress.slice(0, 4)}...${friendlyAddress.slice(-4)}`;
            }
            return '';
        } catch (error) {
            console.error('Error formatting address:', error);
            return '';
        }
    };
    
    const truncateString = (str, maxLength = 20) => {
        if (!str) return '';
        return str.length <= maxLength ? str : `${str.slice(0, maxLength)}...`;
    };
    
    const openTonviewer = (address) => {
        let chain = network === 'Testnet' ? 'testnet.' : '';
        if (window.Telegram?.WebApp) {
            window.Telegram.WebApp.openLink(`https://${chain}tonviewer.com/${address}?section=nft`);
        }
    };

    const handleNftClick = (index) => {
        setSelectedNftIndex(true);
        setNftContent(nfts.filter(nft => nft.type === 'nft')[index]);
        Tabclick();
    }
    const handleSbtClick = (index) => {
        setSelectedNftIndex(true);
        
        setNftContent(nfts.filter(nft => nft.type === 'sbt')[index]);
        Tabclick();
    }
    const handleVaucherClick = (index) => {
        
        setSelectedNftIndex(true);
        setNftContent(nfts.filter(nft => nft.type === "voucher")[index]);
        
        Tabclick();
    }
    
    const copyTonAddress = (tonAddress) => {
        navigator.clipboard.writeText(tonAddress);
        toast.info('Ton address copied to clipboard',{
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
        });
    }
    
    const disconnectWallet = async () => {
        setShowModal(false);
        handleClose();
        console.log('disconnecting wallet');
        try{
        await  tonConnectUI.disconnect();
        setNfts([]);
       
        }catch(error){
            console.log(error);
        }


    };
    


    
    const DescriptionComponent = ({ text, maxLines = 3 }) => {
        if (!text) return null;
        
        const words = text.split(' ');
        const averageWordsPerLine = 10;
        const shouldShowButton = words.length > maxLines * averageWordsPerLine;
        const truncatedWords = isExpanded ? words : words.slice(0, maxLines * averageWordsPerLine);
        
        return (
            <div className="text-sm text-left text-gray-400 overflow-hidden p-1">
                <div className="relative">
                    <p className={`mb-2 ${!isExpanded && 'line-clamp-3'}`}>
                        {truncatedWords.join(' ')}
                    </p>
                    {shouldShowButton && (
                        <button 
                            onClick={() => setIsExpanded(!isExpanded)}
                            className="text-sm bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] bg-clip-text text-transparent font-bold"
                        >
                            {isExpanded ? 'Hide' : 'Read more'}
                        </button>
                    )}
                </div>
            </div>
        );
    };
    
    const sendMint = async () => {
        // alert("ref1: " + refParent1 + " ref2: " + refParent2 + " ref3: " + refParent3 );
        try {
            if (!connected) {
                toast.error('Please connect your wallet');
                return;
            }

            await toast.promise(
                (async () => {
                    handleCloseNft();
                    await mint(ton_adress_sbt_level_1, 25, ton_adress_sbt_level_2, 15, ton_adress_sbt_level_3, 5, 1);
                    await CheckNftsUpdate();
                })(),
                {
                    pending: 'Creating NFT...',
                   // success: 'NFT created successfully!',
                    error: {
                        render({data}) {
                            return `Transaction canceled`;
                        }
                    }
                }
            );

        } catch (error) {
            if (error.message === 'USER_CANCELED_TRANSACTION') {
              
                return;
            }
           
        }
    };
    const sendMintSbt = async () => {
        // alert("ref1: " + refParent1 + " ref2: " + refParent2 + " ref3: " + refParent3 );
        try {
            if (!connected) {
                toast.error('Please connect your wallet');
                return;
            }

            await toast.promise(
                (async () => {
                    handleCloseMintSbt();
                    let referent = ton_adress_sbt_level_1 ? ton_adress_sbt_level_1 : null;
                    await mintsbt(referent);
                    await CheckNftsUpdate();
                })(),
                {
                    pending: 'Creating checking account...',
                   // success: 'NFT created successfully!',
                    error: {
                        render({data}) {
                            return `Transaction canceled`;
                        }
                    }
                }
            );

        } catch (error) {
            if (error.message === 'USER_CANCELED_TRANSACTION') {
              
                return;
            }
           
        }
    };
    
    const sendBoostNft = async (address, boostValue) => {
        if (!boostValue) {
            toast.error('Please select a value to boost NFT');
            return;
        }

        try {

          const sendB = await  sendBoost(address, boostValue, user.user.id)
          if(sendB) {
            setSelectedNftIndex(false);
            setIsLoading(true);
            const initialNftsLength = nfts.length;
            CheckNftsUpdate()
          }

        } catch (error) {
            setIsLoading(false);
            console.error('Ошибка при усилении NFT:', error);
        }
    };
    const sendMintVoucherNew = async (value) => {

       
        toast.promise(
            sendMintVoucher(value),
            {
                pending: 'Minting voucher...',
                //success: 'Voucher minted successfully!',
                error: 'Error minting voucher'
            }
        ).catch((error) => {
            console.log('error:', error);
        }).then((data) => {
            if(data){
                
                setShowModalMintVoucher(false);
                const initialNftsLength = nfts.length;
                CheckNftsUpdate();
            }
        }   );
        
        
    };

    const sendActivateVoucherNow = async (addressvaucher, addressNft) => {
        setShowModalChooseVoucher(false);
        setShowModalChooseNft(false);
        handleCloseNft();
        
        toast.promise(
            sendActivateVoucher(addressvaucher, addressNft),
            {
                pending: 'Activating voucher...',
                //success: 'Voucher activated successfully!',
                error: 'Error activating voucher',
               
               
            }
        ).then((data) => {
            if(data){
                setShowModalChooseVoucher(false);
                CheckNftsUpdate();
            }else{
                toast.error('Error activating voucher');
            }
            
        });
    };
   
   

   useEffect(() => {
    setMathboostValue(Math.floor(total_distance * boostValue / 100));
   }, [boostValue]);
 
    
    
    
   

    useEffect(() => {
        if (connected && nfts.length === 0) {
            fetchNfts();
        }
        console.log('next_item:', next_item);
    }, [connected, next_item]);
     
    
   const openTosty = (text) => {
    toast.promise(
        new Promise((resolve) => setTimeout(resolve, 3000)),
        {
            pending: 'Promise is pending...',
           // success: 'Promise resolved 👌',
            error: 'Promise rejected 🤯'
        }
    );
};

    // Добавить функцию для открытия ссылки в Telegram
    

    const voucherImages = {
        250: require('../img/nft_image/250dp.jpg'),
        500: require('../img/nft_image/500dp.jpg'),
        1000: require('../img/nft_image/1000dp.jpg'),
        5000: require('../img/nft_image/5000dp.jpg')
    };

    async function CheckNftsUpdate () {
        let attempts = 0;
        const maxAttempts = 10;
        const interval = 10000;
        
        const createNftHash = async (nftsArray) => {
            let nftString = nftsArray
                .map(nft => ({
                    address: nft.address,
                    metadata: {
                        name: nft.metadata.name,
                        attributes: nft.metadata.attributes,
                        description: nft.metadata.description
                    },
                    owner: nft.owner.address,
                    type: nft.type
                }))
                .sort((a, b) => a.address.localeCompare(b.address))
                .map(nft => JSON.stringify(nft))
                .join('|');
            const encoder = new TextEncoder();
            const data = encoder.encode(nftString);
            const hashBuffer = await crypto.subtle.digest('SHA-256', data);
            const hashArray = Array.from(new Uint8Array(hashBuffer));
            return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
        };

        const initialHash = await createNftHash(nfts);

        const check = async () => {
            
            if (attempts >= maxAttempts) {
                setIsLoading(false);
                toast.error('Не удалось обновить NFT. Попробуйте позже');
                return;
            }

            try {
                const freshNfts = await fetchNfts();
                await refetch();
                
                let currentHash = await createNftHash(freshNfts);
                
                if (currentHash !== initialHash) {
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    setIsLoading(false);
                    toast.success('Confirmed transaction');
                    const freshNfts = await fetchNfts();
                    await refetch();
                    createStars();
                    return;
                }

                attempts++;
                setTimeout(check, interval);
            } catch (error) {
                console.error('Error checking NFTs:', error);
                attempts++;
                setTimeout(check, interval);
            }
        };

        setIsLoading(true);
        check();
    }

    const onTouchStart = (e) => {
        setTouchEnd(null);
        setTouchStart(e.targetTouches[0].clientY);
    }

    const onTouchMove = (e) => {
        setTouchEnd(e.targetTouches[0].clientY);
    }

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return;
        
        const distance = touchStart - touchEnd;
        const isSwipeDown = distance < -minSwipeDistance;
        
        if (isSwipeDown) {
            handleCloseNft();
        }
    }

    // Добавляем проверку перед маппингом
    if (!user || !user.user) {
        return <div className="text-white">Loading...</div>; // или другой компонент загрузки
    }

    return (
        
        <div>
          
            {connected ? (
               <>
                <div
                    className="text-white cursor-pointer text-center "
                    
                >
                    <span  onClick={() => setShowModal(true)} className=" border border-gray-400 border-1 rounded-3xl px-4  py-1 flex items-center w-fit mx-auto justify-center">
                        
                        <span className="mr-2">{network} </span>
                        <img src={require('../img/ton_gd.png')} alt="wallet icon" className="w-4 h-4 mr-2 bg-white rounded-full overflow-hidden" />
                        {truncateAddress(tonAddress)}

                    </span>
                    <div className="text-white text-sm w-full overflow-hidden">
                        <span className="mr-2">Ref1: {ton_adress_sbt_level_1}</span>
                        <span className="mr-2">Ref2: {ton_adress_sbt_level_2}</span>
                        <span className="mr-2">Ref3: {ton_adress_sbt_level_3}</span>
                    </div>
                    
                </div>
                <button 
                className="button-custom mb-4 rounded-3xl bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] shadow-lg p-2.5 shadow-indigo-500/50 z-10"
                onClick={() => setShowModalMintMenu(true)}
               >
                {content.create_nft} 
           </button>
                </>
                 
            ) : (
                <button className="button-custom mb-4 z-10 rounded-3xl bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] shadow-lg p-2.5 shadow-indigo-500/50"
                    onClick={() => tonConnectModal.open()}>
                     {content.connect_wallet}  
                </button>
            )}
            
            {/* Блок водительского удостоверения */}
            {nfts.filter(nft => nft.type === 'sbt').length > 0 ? (
                nfts.filter(nft => nft.type === 'sbt').map((nft, index) => (
                    <div className="mb-6 mt-4" key={index}>
                        <div className="mb-6  h-[180px] overflow-hidden rounded-2xl bg-gradient-to-br from-[#2e1065] to-[#4c1d95] p-6 shadow-[0_0_15px_rgba(139,92,246,0.3)]">
                            <div className="flex items-start gap-4">
                                {/* Левая часть с фото */}
                                <div className="w-1/4">
                                    <div className="aspect-square rounded-lg overflow-hidden bg-violet-900/30">
                                        <img 
                                            src={nft.previews[3].url} 
                                            alt="Driver Photo"
                                            className="w-full h-full object-cover "
                                        />
                                    </div>
                                </div>
                                
                                {/* Правая часть с информацией */}
                                <div className="flex-1">
                                    <div className="flex justify-between items-start mb-2">
                                        <h3 className="text-violet-300 text-md font-medium">{nft.metadata.name}</h3>
                                        <div className="bg-violet-500/20 px-2 py-1 rounded text-xs text-violet-300">
                                            NFT ID: #{nft.index}
                                        </div>
                                    </div>
                                    
                                    <div className="grid grid-cols-2 gap-4">
                                        <div className="space-y-2">
                                            <div className="text-xs text-gray-400">Name</div>
                                            <div className="text-sm text-white/70">{user.user.first_name} </div>
                                        </div>
                                        <div className="space-y-2">
                                            <div className="text-xs text-gray-400">Address</div>
                                                <div onClick={() => openTonviewer(nft.address)} className="text-sm text-white/70 flex items-center justify-start">{frendlyAddress(nft.address).slice(0, 5)}..{frendlyAddress(nft.address).slice(-5)}</div>
                                        </div>
                                    
                                    </div>
                                    <div className="grid grid-cols-2 gap-2 mt-4">
                                        <div className="space-y-2 ">
                                            <div className="text-xs text-gray-400">Balance</div>
                                            <div className="text-sm text-white flex items-center justify-start ">{nft.contractBalance} <img src={require('../img/ton_gd.png')} alt="wallet icon" className="w-4 ml-2 h-4 mr-2 bg-white rounded-full overflow-hidden" /></div>
                                        </div>
                                        <div className="space-y-2 ">
                                            <button  
                                                 onClick={() => setShowModalClaimTon(true)}
                                                className="px-4 py-2 mt-2 text-sm font-medium text-white bg-gradient-to-r from-violet-600 to-violet-700 rounded-lg 
                                                hover:from-violet-500 hover:to-violet-600 
                                                transition-all duration-300 
                                                shadow-[0_0_10px_rgba(139,92,246,0.3)]  
                                                hover:shadow-[0_0_15px_rgba(139,92,246,0.4)]
                                                border border-violet-500/20 
                                                hover:border-violet-400/30
                                                backdrop-blur-sm
                                                flex items-center gap-2"

                                            >
                                                <span> Claim TON</span> 
                                            </button>    

                                    </div>
                                        
                                    </div>
                              
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <div className="mb-6  mt-4 h-[180px] overflow-hidden rounded-2xl bg-gradient-to-br from-[#2e1065] to-[#4c1d95] p-6 shadow-[0_0_15px_rgba(139,92,246,0.3)]">
                    <div className="flex flex-col items-center justify-center h-full gap-4">
                        <p className="text-gray-400 text-md">Digital Driver Permit (SBT) not found</p>
                        <button onClick={() => {setShowModalMintSbt(true);}} className="bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] text-white rounded-lg p-2 w-3/4">
                            Create a checking account
                        </button>   
                    </div>
                </div>
            )}   
            <ProkaChka />
            {/* Существующий код коллекций */}
             
               
            <div className="flex justify-center items-center mb-2 p-2">
           
            {connected && nfts.length > 0 ? (
            <div>   
                
                <h1 className="text-left text-white text-2 mt-4">Collections</h1>
                <ul className="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-3 gap-2 mt-4">
                    {[...Array(20)].map((_, index) => {
                        const nft = nfts.filter(nft => nft.type === 'nft')[index];
                        
                        return nft ? (
                            // Существующий NFT
                            <li key={index} 
                                onClick={() => handleNftClick(index)} 
                                className="bg-gradient-to-br from-[#2e1065]/50 to-[#4c1d95]/50 p-0 overflow-hidden rounded-lg shadow-md relative">
                                <div className="relative">
                                    <img src={nft.previews[3].url} 
                                         alt={nft.metadata.name} 
                                         className="w-full h-auto mb-2" />
                                    {nft.metadata.attributes?.find(attr => attr.trait_type === 'Distance') && (
                                        <div className="absolute top-2 right-2 bg-gradient-to-br from-[#2e1065]/90 to-[#4c1d95]/90 rounded-md p-0.5">
                                            <span className="text-xs font-sm font-bold text-gray-400 p-0 line-clamp-1">
                                                {nft.metadata.attributes?.find(attr => attr.trait_type === 'Distance')?.value || '-'}
                                            </span>
                                        </div>
                                    )}
                                </div>
                                <p className="text-sm text-center text-gray-400 overflow-hidden p-1">
                                    {truncateString(nft.metadata.name)}
                                </p>
                            </li>
                        ) : (
                            // Заглушка
                            <li key={`placeholder-${index}`} 
                                onClick={index === nfts.filter(nft => nft.type === 'nft').length ? 
                                   () => setShowModalMint(true) : 
                                   undefined}
                                className={`bg-gradient-to-br from-[#2e1065]/50 to-[#4c1d95]/50 p-0 overflow-hidden rounded-lg shadow-md relative 
                                    ${index === nfts.filter(nft => nft.type === 'nft').length ? 'border-2 border-white/70' : ''}`}>
                                <div className="relative">
                                    <img src={require('../img/nft_image/carNft_one.jpg')} 
                                         alt="Placeholder" 
                                         className="w-full mb-2 filter grayscale opacity-50" />
                                    <div className="absolute inset-0 flex items-center justify-center">
                                        {index === nfts.filter(nft => nft.type === 'nft').length ? (
                                            <svg xmlns="http://www.w3.org/2000/svg" 
                                                 className="h-8 w-8 text-white/70" 
                                                 fill="none" 
                                                 viewBox="0 0 24 24" 
                                                 stroke="currentColor">
                                                <path strokeLinecap="round" 
                                                      strokeLinejoin="round" 
                                                      strokeWidth={2} 
                                                      d="M12 4v16m8-8H4" />
                                            </svg>
                                        ) : (
                                            <div className="bg-gradient-to-br from-[#2e1065]/90 to-[#4c1d95]/90 rounded-md p-1">
                                                <span className="text-xs font-sm font-bold text-white/70 p-0 line-clamp-1">                                         
                                                     {index === nfts.filter(nft => nft.type === 'nft').length ? 'Enable' : 'Disabled'}                                               
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                    <p className="text-sm text-center text-gray-400 overflow-hidden p-1">
                                      
                                        {['Speed Master', 'Night Driver', 'Road Legend', 'City Racer', 'Street King'][index % 5]}
                                   
                                    </p>
                                </div>
                            </li>
                        );
                    })}
                </ul>
                <h1 className="text-left text-white text-2 mt-4">{content.your_voucher}</h1>
                <ul className="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-3 gap-2 mt-4">
                    {nfts.filter(nft => nft.type === 'voucher').map((nft, index) => (
                        <li key={nft.index} onClick={() => handleVaucherClick(index)} data-index={index} className="bg-gradient-to-br from-[#2e1065]/50 to-[#4c1d95]/50 p-0 overflow-hidden rounded-lg shadow-md relative">
                            <div className="relative"> 
                                <img src={nft.previews[3].url} alt={nft.metadata.name} className="w-full h-auto mb-2" />
                                
                                {nft.metadata.attributes?.find(attr => attr.trait_type === 'Distance') && (
                                <div className="absolute top-2 right-2  bg-gradient-to-br from-[#2e1065]/90 to-[#4c1d95]/90 rounded-md p-0.5 ">
                                    <span className="text-xs font-sm font-bold text-gray-400 p-0 line-clamp-1">
                                        {nft.metadata.attributes?.find(attr => attr.trait_type === 'Distance')?.value || '-'}
                                    </span>
                                </div>
                                )}
                            </div>
                            <p className="text-sm text-center text-gray-400 overflow-hidden p-1">{truncateString(nft.metadata.name)}</p>
                            
                        </li>
                    ))}
                </ul>
            </div>
            )
                :
            connected && (
            <div className="text-left text-sm mb-4 text-gray-400 mt-4 p-2 space-y-4">
                <div className="flex flex-col items-center justify-center">
                    <p className="font-bold text-base mb-2 text-center">{content.title}</p>
                </div>

                <div className="bg-gradient-to-br from-[#2e1065]/30 to-[#4c1d95]/30 p-4 rounded-lg">
                    <p className="mb-4">
                        {content.text1}
                        <div className="mt-2 space-y-1 whitespace-pre-line">
                            {content.text2}
                        </div>
                    </p>

                    <p className="mb-4">
                        {content.text3}
                        <span className="font-bold text-indigo-400"> {content.text4}</span>
                    </p>

                    <p className="italic">
                        {content.text5}
                    </p>
                </div>
            </div>
            )}  


        </div>
        <Marketplace />
        <ToastContainer          
        position="top-center"
        autoClose={2000}        
        theme="dark"  
        // toastClassName={({ type }) => 
        //     type === 'success' 
        //         ? 'bg-gray-500 rounded-lg'
        //         : undefined
        // }      
        /> 
        
        <CustomModalReactWalletSmall isOpen={showModal} onClose={handleClose}> 
            <div className="absolute top-1/4 left-0 right-0 flex flex-col space-y-4 items-center w-full mt-4">
                <button onClick={() => copyTonAddress(tonAddress)} className="bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] text-white rounded-lg p-2 w-3/4">
                    {content.copy_address}
                </button>
                <button onClick={disconnectWallet} className="text-gray-400 rounded-lg p-2 w-3/4">
                    {content.disconnect_wallet}
                </button>            
            </div>
        </CustomModalReactWalletSmall>
        <CustomModalReactWalletSmall isOpen={showModalMintMenu} onClose={handleCloseMintMenu}> 
            <div className="absolute top-1/4 left-0 right-0 flex flex-col space-y-4 items-center w-full mt-4">
                <button onClick={() => { handleCloseMintMenu(); setShowModalMint(true); }} 
                        className="bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] text-white rounded-lg p-2 w-3/4">
                    {content.nft}
                </button>
                <button onClick={() => { handleCloseMintMenu(); setShowModalMintVoucher(true); }} 
                        className="bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] text-white rounded-lg p-2 w-3/4">
                    {content.voucher}
                </button>
            </div>
        </CustomModalReactWalletSmall>
        <CustomModalReactWallet isOpen={showModalChooseVoucher} onClose={handleCloseChooseVoucher}> 
            <div className="absolute top-[12%] left-0 right-0 flex flex-col space-y-2 items-center w-full pl-2 pr-2 overflow-y-auto max-h-[50vh]">
                
                <div className=" text-white text-lg   font-bold ">{content.choose_voucher}</div>
                <div><ul className="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-3 gap-2 ">
                { nfts.filter(nft => nft.type === 'voucher')
                     .map((nft, index) => (
                         <li key={index} 
                             onClick={() => sendActivateVoucherNow(nft.address, nftActivateVoucher)} 
                             data-index={index} 
                             className="bg-gradient-to-br from-[#2e1065]/50 to-[#4c1d95]/50 p-0 overflow-hidden rounded-lg shadow-md relative">
                             <div className="relative">
                                 <img src={nft.previews[3].url} alt={nft.metadata.name} className="w-full h-auto mb-2" />
                                 
                             </div>
                             <p className="text-sm text-center text-gray-400 overflow-hidden p-1">{truncateString(nft.metadata.name)}</p>
                         </li>
                     ))}
             </ul></div>
        </div>
    </CustomModalReactWallet>
    <CustomModalReactWallet isOpen={showModalChooseNft} onClose={handleCloseChooseNft}> 
            <div className="absolute top-[12%] left-0 right-0 flex flex-col space-y-2 items-center w-full pl-2 pr-2  overflow-y-auto max-h-[50vh]">
           


            <div className=" text-white text-lg   font-bold ">{content.choose_nft}</div>
           <div><ul className="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-3 gap-2 ">
           { nfts.filter(nft => nft.type === 'nft')
                .map((nft, index) => (
                    <li key={index} 
                        onClick={() => sendActivateVoucherNow(nftActivateVoucher,nft.address)} 
                        data-index={index} 
                        className="bg-gradient-to-br from-[#2e1065]/50 to-[#4c1d95]/50 p-0 overflow-hidden rounded-lg shadow-md relative">
                        <div className="relative">
                            <img src={nft.previews[3].url} alt={nft.metadata.name} className="w-full h-auto mb-2" />
                            {nft.metadata.attributes?.find(attr => attr.trait_type === 'Distance') && (
                                <div className="absolute top-2 right-2  bg-gradient-to-br from-[#2e1065]/90 to-[#4c1d95]/90 rounded-md p-0.5 ">
                                    <span className="text-xs font-sm font-bold text-gray-400 p-0 line-clamp-1">
                                        {nft.metadata.attributes?.find(attr => attr.trait_type === 'Distance')?.value || '-'}
                                    </span>
                                </div>
                                )}
                        </div>
                        
                        <p className="text-sm text-center text-gray-400 overflow-hidden p-1">{truncateString(nft.metadata.name)}</p>
                    </li>
                ))}
        </ul></div>
    </div>
</CustomModalReactWallet>
        
        <CustomModalMintNft isOpen={showModalMint} onClose={handleCloseMint}>
            <div className="w-full justify-center content-center items-center overflow-y-auto max-h-[95vh] mb-[10%]">
            <div className=" text-white text-lg mx-auto text-center w-full font-bold ">Минт NFT</div>
                <>
                    <img
                        //src={ require('../img/nft_image/carNft_one.jpg')}                        
                        src={ require('../img/nft_image/1.jpg')}                        
                        className="w-full mt-2 rounded-t-lg"
                    />
                    <div className="w-full mx-auto mt-4">
                        <h1 className="text-lg text-left text-white-400 overflow-hidden p-1">
                            {content.nft_collection_title}
                        </h1>
                       
                            <DescriptionComponent maxLines={3} text={content.nft_collection_description} />
                       
                    </div>
                    <div className="w-full mx-auto mt-4">
                        <button onClick={() => {sendMint(); handleCloseMint(); }} className="rounded-3xl bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] shadow-lg p-2.5 shadow-indigo-500/50 w-full">
                            {content.mint_nft}
                        </button>
                    </div>
                </>
            </div>
        </CustomModalMintNft>
        <CustomModalMintNft isOpen={showModalMintSbt} onClose={handleCloseMintSbt}>
            <div className="w-full justify-center content-center items-center  overflow-y-auto max-h-[95vh] mb-[10%]">
            <div className=" text-white text-lg mx-auto text-center w-full font-bold ">Create a checking account</div>
                <>
                    <img
                        //src={ require('../img/nft_image/carNft_one.jpg')}                        
                        src={ require('../img/nft_image/carNft_one.jpg')}                        
                        className="w-full mt-2 rounded-t-lg"
                    />
                    <div className="w-full mx-auto mt-4">
                        <h1 className="text-lg text-left text-white-400 overflow-hidden p-1">
                           Digital Driver Permit (SBT)
                        </h1>
                       
                            <p className="text-gray-400 text-sm">На этот счет будет начисляться  ваш Ton от рефералов и вознаграждения от приложения </p>
                       <p className="text-gray-400 text-sm">Счет это смарт контракт </p>
                       <p className="text-gray-400 text-sm">С этого счета вывести деньги можете только Вы и только на свой кошелек</p>
                    </div>
                    <div className="w-full mx-auto mt-4">
                        <button onClick={() => {sendMintSbt(); handleCloseMintSbt(); }} className="rounded-3xl bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] shadow-lg p-2.5 shadow-indigo-500/50 w-full">
                            Создать счет
                        </button>
                    </div>
                </>
            </div>
        </CustomModalMintNft>





        
        <CustomModalMintNft isOpen={showModalMintVoucher} onClose={handleCloseMintVoucher}>
            <div className="w-full justify-center content-center items-center  overflow-y-auto max-h-[95vh] mb-[10%]">
                <>  
                <div className=" text-white text-lg mx-auto text-center w-full font-bold ">Минт Ваучера</div>
                    <img
                        src={voucherImages[boostValueVaucher] || require('../img/nft_image/250dp.jpg')}                        
                        className="w-full mt-2 rounded-t-lg"
                        alt={`Voucher ${boostValueVaucher} dp`}
                    />
                    <div className="w-full mx-auto mt-4">
                        <h1 className="text-lg text-left text-white-400 overflow-hidden p-1">{content.voucher_title}</h1>
                       
                            <DescriptionComponent maxLines={3} text={content.voucher_description} />
                       
                    </div>
                    <div className="w-full mx-auto mt-4">
                        
                            <button 
                                disabled={total_distance < boostValueVaucher}
                                onClick={() => {
                                    if (total_distance >= boostValueVaucher) {
                                        sendMintVoucherNew(boostValueVaucher);
                                    } else {
                                        toast.error(`${content.not_enough_dp}${boostValueVaucher}, ${content.available}${total_distance}`);
                                    }
                                }}  
                                style={total_distance >= boostValueVaucher ? 
                                    {opacity: 1, cursor: 'pointer'} : 
                                    {opacity: 0.5, cursor: 'not-allowed'}
                                } 
                                className="rounded-3xl bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] shadow-lg p-2.5 shadow-indigo-500/50 w-full"
                            >
                                {content.create_voucher} {boostValueVaucher ? boostValueVaucher : 0} dp
                                {total_distance < boostValueVaucher && 
                                    <div className="text-xs mt-1">
                                        {content.need_more}{boostValueVaucher - total_distance}{content.more_dp}
                                    </div>
                                }
                            </button>
                        </div>
                     

                        <div className='text-center pl-2 pr-2  mt-8  text-gray-400' style={{ fontSize: '12px' }}>
                        {content.choose_value}{total_distance}{content.dp}
                        </div>

                    <div className="mb-6 pr-2 pl-2 w-full">
    <input
        type="range"
        min="0"
        max="3"
        step="1"
        value={[ 250, 500, 1000, 5000].indexOf(parseInt(boostValueVaucher))}
        onChange={(e) => {
            const values = [ 250, 500, 1000, 5000];
            setBoostValueVaucher(values[e.target.value]);
        }}
        className="w-full h-2 rounded-lg appearance-none cursor-pointer 
        bg-gradient-to-r from-[#4f46e5] to-[#7c3aed]
        [&::-webkit-slider-thumb]:appearance-none
        [&::-webkit-slider-thumb]:h-4 
        [&::-webkit-slider-thumb]:w-6 m-2
        [&::-webkit-slider-thumb]:rounded-full
        [&::-webkit-slider-thumb]:bg-white
        [&::-webkit-slider-thumb]:shadow-lg
        [&::-webkit-slider-thumb]:shadow-indigo-500/50"
    />
    <div className="flex justify-between">
        
        <span className="text-xs text-gray-400 font-bold">250 dp</span>
        <span className="text-xs text-gray-400 font-bold">500 dp</span>
        <span className="text-xs text-gray-400 font-bold">1000 dp</span>
        <span className="text-xs text-gray-400 font-bold">5000 dp</span>
    </div>
</div>
                </>
            </div>
        </CustomModalMintNft>
     <CustomModalClaimTon isOpen={showModalClaimTon} onClose={handleCloseClaimTon}>
        <SwapTonInput maxBalance={ton_balance} onClose={() => handleCloseClaimTon(false)} />
        
     </CustomModalClaimTon>

        {nft_content && (
    <CustomModalShowNft isOpen={selectedNftIndex} onClose={handleCloseNft}>
        <div className="">
             <div className="header-modal">
              
                <div className="modal-title text-white text-lg font-bold text-center">{nft_content.metadata.name}</div>
            </div>
            <div className="w-full justify-center content-center items-center"> 
                
                <> 
                    <img  
                        src={nft_content.previews?.[3]?.url || nft_content.metadata?.image || require('../img/ton_gd.png')} 
                        alt={nft_content.metadata?.name} 
                        className="w-full  mt-2 rounded-t-lg"
                    />
                    <div className="rounded-b-lg  p-2 bg-card text-card-foreground  border-0 bg-gradient-to-br from-[#2e1065]/50 to-[#4c1d95]/50 shadow-[0_0_15px_rgba(139,92,246,0.15)] ">
                    
                    
                    <p className="mb-2 flex items-center gap-2 text-md font-semibold text-white">
                    <BatteryCharging  size={32} className=" text-violet-400" />
                        
                       {content.power_nft} : <span>{nft_content.metadata?.attributes?.find(attr => attr.trait_type === 'Distance')?.value || '0 dp'} </span></p>
                    


                    </div>



                    <div className="w-full mx-auto mt-4">
                       <h1 className="text-lg text-left  text-white-400 overflow-hidden p-1">{nft_content.metadata.name}</h1>
                       {nft_content?.metadata?.description && (
                           <DescriptionComponent text={nft_content.metadata.description} />
                       )}
                    
                    </div>
                    {nft_content.type === 'nft' ? (
                    <div className="w-full mx-auto mt-4">
                        <button 
                            onClick={async () => {
                                if (isLoading) return;
                                setIsLoading(true);
                                try {
                                    await sendBoostNft(nft_content.address, mathboostValue);
                                    setBoostValue(0);
                                } finally {
                                    setIsLoading(false);
                                }
                            }}  
                            disabled={!mathboostValue || isLoading}
                            style={(!mathboostValue || isLoading) ? {opacity: 0.5, cursor: 'not-allowed'} : {}} 
                            className="rounded-3xl bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] shadow-lg p-2.5 shadow-indigo-500/50 w-full"
                        >
                            {isLoading ? 'Processing...' : `${content.nft_boost} ${mathboostValue} dp`}
                        </button>
                    </div>):""}
                 
                    {nft_content.type === 'nft' ? (
                    <div className='text-center pl-2 pr-2  mt-8  text-gray-400' style={{ fontSize: '12px' }}>
                    {content.choose_value} {total_distance} {content.dp}
                    </div> ) :""}

                    {nft_content.type === 'nft' ? (
                    <div className="mb-6 pr-2 pl-2  w-full">
                        <input
                            type="range"
                            min="0"
                            max="100"
                            step="1"
                            value={boostValue}
                            onChange={(e) => setBoostValue(e.target.value) }
                            className="w-full h-2  rounded-lg appearance-none cursor-pointer 
                            bg-gradient-to-r from-[#4f46e5] to-[#7c3aed]
                            [&::-webkit-slider-thumb]:appearance-none
                            [&::-webkit-slider-thumb]:h-4 
                            [&::-webkit-slider-thumb]:w-6 m-2
                            [&::-webkit-slider-thumb]:rounded-full
                            [&::-webkit-slider-thumb]:bg-white
                            [&::-webkit-slider-thumb]:shadow-lg
                            [&::-webkit-slider-thumb]:shadow-indigo-500/50"
                        />
                        <div className="flex justify-between  ">
                            <span className="text-xs text-gray-400   font-bold">0%</span>
                            <span className="text-xs text-gray-400  font-bold">25%</span>
                            <span className="text-xs text-gray-400  font-bold">50%</span>
                            <span className="text-xs text-gray-400   font-bold">75%</span>
                            <span className="text-xs text-gray-400   font-bold">100%</span>
                        </div>
                    </div>
                    ):""}
                    
                    
                    
                    
                    
                    {nft_content.type === 'voucher' && (
                        <div>
                            <button onClick={() => {functionsetShowModalChooseNft(nft_content.address)}}
                                className="rounded-3xl bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] shadow-lg p-2.5 shadow-indigo-500/50 w-full">
                                {content.apply_voucher}
                            </button>
                        </div>
                    )}

                    <div className="w-full mx-auto  mt-10 mb-4 bg-gradient-to-br  rounded-lg from-[#2e1065]/30 to-[#4c1d95]/30 mb-1">
                    <div className="flex justify-between items-center p-2 rounded-lg ">
                                <span className="text-sm text-gray-400">{content.buy_sell}</span>
                                {console.log('nft_content.address', nft_content.collection.address)}
                                <span 
                                    className="text-sm underline  text-right font-bold cursor-pointer bg-gradient-to-r from-violet-500 via-fuchsia-500 to-violet-500 animate-gradient bg-clip-text text-transparent transition-all hover:opacity-80" 
                                    onClick={() => {window.open(`https://testnet.getgems.io/collection/${frendlyAddress(nft_content.collection.address)}`, '_blank')}}
                                    // onClick={() => {window.open(`https://testnet.getgems.io/collection/${frendlyAddress(nft_content.collection.address)}/${frendlyAddress(nft_content.address)}`, '_blank')}}
                                >
                                    GetGems
                                </span>
                            </div>
                        <div className="flex justify-between items-center p-2 ">
                                <span className="text-sm text-gray-400 font-medium ">{content.details}</span>
                                <span 
                                    className="text-sm text-right text-white font-medium underline cursor-pointer" 
                                    onClick={() => openTonviewer(nft_content.address)}
                                > 
                                    Tonviewer
                                </span> 
                            </div>
                        <div className="w-full  ">
                            <div className="flex justify-between items-center p-2 rounded-lg ">
                                <span className="text-sm text-gray-400">{content.owner}</span>
                                <span className="text-sm text-right text-white font-medium cursor-pointer" onClick={() => copyTonAddress(nft_content.owner.address)}>{truncateAddress(nft_content.owner.address)}</span>
                            </div>
                            <div className="flex justify-between items-center p-2 rounded-lg ">
                                <span className="text-sm text-gray-400">{content.contract_address}</span>
                                {console.log('nft_content.address', nft_content)}
                                <span className="text-sm text-right text-white font-medium  cursor-pointer" onClick={() => copyTonAddress(nft_content.address)}>{truncateAddress(nft_content.address)}</span>
                            </div>
                           
                        </div>
                    </div>
                    <div className="w-full mx-auto mb-4"></div>
                    {nft_content.type === 'voucher' && (
                        <div>
                            <button  onClick={() => {window.open(`https://testnet.getgems.io/collection/${frendlyAddress(nft_content.collection.address)}/${frendlyAddress(nft_content.address)}`, '_blank')}}
                                className="rounded-3xl bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] shadow-lg p-2.5 shadow-indigo-500/50 w-full">
                                {content.sell_nft_voucher}
                            </button>
                        </div>
                    )}
                    {nft_content.type === 'nft'  &&  nfts.filter(nft => nft.type === 'voucher').length > 0 ? (
                        <div>
                            <button onClick={() => {functionsetShowModalChooseVoucher(nft_content.address)}}  
                                className="rounded-3xl bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] shadow-lg p-2.5 shadow-indigo-500/50 w-full">
                                {content.apply_voucher}
                            </button>
                        </div>
                    ) : ""}
                     {nft_content.type === 'nft'  &&  nfts.filter(nft => nft.type === 'voucher').length < 1 ? (
                        <div>
                            <button onClick={() => {window.open('https://testnet.getgems.io/collection/kQC1_JExO6dVdtle7__5ypc-bIRrsdk7D5fcsQP5_FFoz9ww', '_blank')}}
                                className="rounded-3xl bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] shadow-lg p-2.5 shadow-indigo-500/50 w-full">
                                {content.buy_voucher} 
                            </button>
                        </div>
                    ) : ""}

                     </>
            
        </div>
           
        </div>
        
        </CustomModalShowNft>
        )}
       
    </div>



   

);
};

export default WalletConnectButton;