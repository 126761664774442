import React, { useState, useEffect, useContext } from 'react';
import ConfirmModal from './ConfirmModal';
import { UserContext } from '../UserContext';
import Getcontext from './Getcontext.js';
import { useNavigate } from 'react-router-dom';

const LoadClubs = () => {
    const language = localStorage.getItem('language_code') || 'en';
    const content = Getcontext({ language: language, contextKey: 'clubs' });
    
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedClub, setSelectedClub] = useState(null);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [joiningClub, setJoiningClub] = useState(false);
    const { user, clubsData, updateClubs , clubs, setClubs} = useContext(UserContext);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchClubs = async () => {
            try {
                // Проверяем кэш
                const CACHE_TIME = 5 * 60 * 1000; // 5 минут
                if (clubsData.clubs.length > 0 && 
                    clubsData.lastFetched && 
                    Date.now() - clubsData.lastFetched < CACHE_TIME) {
                    setLoading(false);
                    return;
                }

                const response = await fetch('https://drivecoin.io/api/clubs');
                
                if (!response.ok) {
                    throw new Error('Failed to fetch clubs');
                }

                const data = await response.json();
                updateClubs(data);
            } catch (err) {
                console.error('Error fetching clubs:', err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchClubs();
    }, [clubsData.lastFetched, updateClubs]);

    const handleJoinClub = (club) => {
        console.log(club);
        setSelectedClub(club);
        setShowConfirmModal(true);
    };

    const handleConfirmJoin = async () => {
        if (!selectedClub || joiningClub) return;

        setJoiningClub(true);
        try {  
            console.log(selectedClub);
            const response = await fetch('https://drivecoin.io/api/clubs/join', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    clubId: selectedClub.id,
                    userId: user.user.id
                })
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error || 'Ошибка при вступлении в клуб');
            }else{
                setClubs(selectedClub.id);
            }

            // Обновляем список клубов после успешного вступления
            updateClubs(clubsData.clubs.map(club => {
                if (club.id === selectedClub.id) {
                    return {
                        ...club,
                        members_count: club.members_count + 1,
                        members_avatars: [
                            ...(club.members_avatars || []),
                            user.user.photo_url
                        ].slice(0, 5)
                    };
                }
                return club;
            }));

            // Показываем уведомление об успехе
            alert('Вы успешно вступили в клуб!');

        } catch (error) {
            console.error('Ошибка при вступлении в клуб:', error);
            alert(error.message);
        } finally {
            setJoiningClub(false);
            setShowConfirmModal(false);
            setSelectedClub(null);
        }
    };

    const handleClubClick = (clubId, event) => {
        // Проверяем, что клик не был по кнопке "Присоединиться"
        if (!event.target.closest('button')) {
            navigate(`/club/${clubId}`);
        }
    };

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
            {clubs && clubsData.clubs.filter(club => club.id === clubs).map((club) => (
                <div 
                    key={club.id}
                    className="bg-gradient-to-br from-[#2e1065] to-[#4c1d95] rounded-lg p-3 shadow-lg cursor-pointer hover:opacity-90 transition-opacity mb-4"
                    onClick={(e) => handleClubClick(club.id, e)}
                >
                    <div className="flex gap-3">
                        <img 
                            src={require(`../img/clubs/${club.photo_url}`)}
                            alt={club.name} 
                            className="w-20 h-20 object-cover rounded-lg flex-shrink-0"
                        />
                        <div className="flex-1 min-w-0">
                            <h3 className="text-lg font-bold truncate">{club.name}</h3>
                            <div className="text-gray-300 text-xs">
                                {content.rating}: {isNaN(club.total_club_distance) ? 0 : (
                                    <>
                                        <img src={require('../img/dp.png')} alt='dp' className='w-2 h-2 inline-block' />
                                        {parseFloat(club.total_club_distance).toFixed(0)}
                                    </>
                                )} • {content.all_users}: {club.members_count}
                            </div>
                            
                            <p className="text-gray-300 text-sm mt-1 line-clamp-2">{club.description}</p>
                            
                            <div className="flex items-center mt-2 justify-between mt-2">
                                <div className="flex -space-x-2">
                                    {club.members_avatars && club.members_avatars.slice(0, 3).map((avatar, index) => (
                                        <img
                                            key={index}
                                            src={avatar}
                                            alt={`Member ${index + 1}`}
                                            className="w-6 h-6 rounded-full border-2 border-[#2e1065]"
                                        />
                                    ))}
                                    {club.members_count > 3 && (
                                        <div className="w-6 h-6 rounded-full bg-purple-500 flex items-center justify-center text-xs border-2 border-[#2e1065]">
                                            +{club.members_count - 3}
                                        </div>
                                    )}
                                    
                                </div>
                                <div className="px-3 py-1 bg-gradient-to-r from-emerald-500 to-emerald-700 rounded-lg text-white text-sm font-medium shadow-sm">
                                    Мой клуб
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            ))}
            {clubsData.clubs.map((club) => 
                clubs !== club.id && (
                    <div 
                        key={club.id} 
                        className="bg-[#2a1b3e] rounded-lg p-3 shadow-lg cursor-pointer hover:bg-[#372852] transition-colors"
                        onClick={(e) => handleClubClick(club.id, e)}
                    >
                        <div className="flex gap-3">
                            <img 
                                src={require(`../img/clubs/${club.photo_url}`)}
                                alt={club.name} 
                                className="w-20 h-20 object-cover rounded-lg flex-shrink-0"
                            />
                            <div className="flex-1 min-w-0">
                                <h3 className="text-lg font-bold truncate">{club.name}</h3>
                                <div className="text-gray-300 text-xs">
                                    {content.rating}: {isNaN(club.total_club_distance) ? 0 : (
                                        <>
                                            <img src={require('../img/dp.png')} alt='dp' className='w-2 h-2 inline-block' />
                                            {parseFloat(club.total_club_distance).toFixed(0)}
                                        </>
                                    )} • {content.all_users}: {club.members_count}
                                </div>
                                
                                <p className="text-gray-300 text-sm mt-1 line-clamp-2">{club.description}</p>
                                
                                <div className="flex items-center justify-between mt-2">
                                    <div className="flex -space-x-2">
                                        {club.members_avatars && club.members_avatars.slice(0, 3).map((avatar, index) => (
                                            <img
                                                key={index}
                                                src={avatar}
                                                alt={`Member ${index + 1}`}
                                                className="w-6 h-6 rounded-full border-2 border-[#2a1b3e]"
                                            />
                                        ))}
                                        {club.members_count > 3 && (
                                            <div className="w-6 h-6 rounded-full bg-purple-500 flex items-center justify-center text-xs border-2 border-[#2a1b3e]">
                                                +{club.members_count - 3}
                                            </div>
                                        )}
                                    </div>
                                    <button
                                        onClick={() => handleJoinClub(club)}
                                        disabled={joiningClub}
                                        className={`px-3 py-1 bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] hover:bg-purple-700 rounded-lg text-white text-sm transition-colors ${
                                            joiningClub ? 'opacity-50 cursor-not-allowed' : ''
                                        }`}
                                    >
                                        {joiningClub ? content.joining : content.join}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            )}
            {showConfirmModal && (
                <ConfirmModal 
                    clubName={selectedClub?.name}
                    onClose={() => {
                        setShowConfirmModal(false);
                        setSelectedClub(null);
                    }}
                    onConfirm={handleConfirmJoin}
                />
            )}
        </div>
   
    );
}
export default LoadClubs; 