import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../UserContext';
import { toast } from 'react-toastify';
import { createStars } from './ConfettiEffect';


import { useContractMint } from '../Hook/useContractNft.ts';

const SwapTonInput = ({ maxBalance = 100, onClose }) => {
    const [percentage, setPercentage] = useState(10);
    const [tonAmount, setTonAmount] = useState(0);
    const [pointsAmount, setPointsAmount] = useState(0);
    const {ton_adress_sbt, ton_balance, setTon_balance, user, total_distance, setTotal_distance} = useContext(UserContext);
    // Коэффициент конвертации (например, 1 TON = 100 points)
    const { сlaimTonSbt} = useContractMint();
    const CONVERSION_RATE = 10;


    async function burnDrivePoints(pointsAmount){
        try {
            const response = await fetch('https://drivecoin.io/api/device-operations', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
              },
              body: JSON.stringify({
                operation: 'burnDrivePoints',
                user_id_tg: user.user.id,
                points_amount: pointsAmount
              })
            });
        
            if (!response.ok) {
              const errorData = await response.json();
              throw new Error(errorData.error || 'Ошибка сервера');
            }
      
            const results = await response.json();
           
            return results;
        } catch (error) {
            toast.error('Failed to burn Drive Points');
            return false;
        }
            
    }
    const sbtclaim = async (summa, pointsAmount) => {
       
       
       
        toast.promise(сlaimTonSbt(ton_adress_sbt,summa).then((result)=>{
        
                if(result){
                    burnDrivePoints(pointsAmount);
                    setTon_balance(ton_balance - summa); 
                    setTotal_distance(total_distance - pointsAmount);
                    createStars();
                    onClose();
                }
                
                }).catch((error)=>{
                toast.error('Failed to claim TON');
                }), {
                
                pending: 'Claiming TON...',
                success:  "TON claimed successfully!" ,
                error: 'Failed to claim TON'
                })
            
          
      };
    useEffect(() => {
        const calculatedTon = ((maxBalance-0.05) * percentage) / 100;
        setTonAmount(parseFloat(calculatedTon.toFixed(2)));
        setPointsAmount(calculatedTon * CONVERSION_RATE);
    }, [percentage, maxBalance]);

    return (
        <div className="bg-gradient-to-r from-violet-900/20 to-purple-900/20 rounded-2xl p-6 border border-violet-500/10">
            <div className="mb-6">
                <div className="flex justify-between items-center mb-2">
                    <span className="text-gray-300">Количество TON</span>
                    <span className="text-sm text-gray-400">
                        Доступно: {maxBalance.toFixed(2)} TON
                    </span>
                </div>
                
                <div className="flex items-center gap-4 mb-4">
                    <input
                        type="range"
                        min="10"
                        max="100"
                        step="10"
                        value={percentage}
                        onChange={(e) => setPercentage(Number(e.target.value))}
                        className="w-full accent-violet-500"
                    />
                    <span className="text-lg font-bold text-white min-w-[3rem]">
                        {percentage}%
                    </span>
                </div>

                <div className="bg-violet-500/10 rounded-xl p-4 mb-4">
                    <div className="text-2xl font-bold text-white text-center">
                        {tonAmount} TON
                    </div>
                </div>

                <div className="text-center">
                    <div className="text-violet-300 mb-1">Сжигается</div>
                    <div className="text-xl font-bold text-white">
                        {Math.floor(pointsAmount)} Drive Points
                    </div>
                </div>
            </div>

            <button 
                className="w-full py-3 px-4 rounded-full bg-violet-500 hover:bg-violet-600 text-white font-medium transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                disabled={tonAmount <= 0}
                onClick={() => {
                    sbtclaim(tonAmount, pointsAmount);
                    // Здесь будет логика обмена
                    console.log(`Получить ${tonAmount} TON за ${pointsAmount} Drive Points`);
                }}
            >
                Получить
            </button>
        </div>
    );
};

export default SwapTonInput; 