import React, { useState, useRef, useEffect, useContext } from 'react';
import ListGroupItem from './ListGroupItem';
import { Button, Overlay } from 'react-bootstrap';
import BreathingButton from './ButtonMain.js';
import CarouselComponent from './CarouselComponent.js'; // Импортируем компонент карусели
import CardGridComponent from './CardGridComponent.js'; // Импортируем компонент карточек
import TelegramAccount from './TelegramAccount.js';
import CoinDrive from './CoinDrive';
import SwiperComponent from './SwiperComponent.js';
import ImageComponent from './ImageComponent.js';
import SwiperComponentBig from './SwiperComponentbig.js';
import ToggleSwitch from './swich.js';
import { CustomModal ,CustomModalClaimTon, CustomModalReactNft} from './CustomModalReact.js';
import { Zap, Info, HelpCircle, ShoppingBag } from 'lucide-react';
import InfoCards from './CardTailwin.js';
import Getcontext from './Getcontext.js';
import DailyStatsChart from './DailyStatsChart';
import { BoosterBottomSheet } from './BoosterBottomSheet.js';
import Onboarding from './Onboarding';
import BaseSBT from './BaseSBT';  
import { UserContext } from '../UserContext.js';
import { toast, ToastContainer } from 'react-toastify';

import GetTonModal from './GetTonModal';
import SwapTonInput from './SwapTonInput';
import useNft from '../Hook/useNft.ts';
import { useTonClient } from '../Hook/useTonClient';
import {useTonConnect} from '../Hook/useTonconnect.ts';

const Main = () => {
   
    const {  user_photo_url, user, nfts, ton_address ,walletAddress,  onConnectWallet, onCreateAccount, ton_balance, setWalletAddress } = useContext(UserContext);
    const [activeSlide, setActiveSlide] = useState(0);
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);
    const slideContainerRef = useRef(null);
    const [isBoostSheetOpen, setIsBoostSheetOpen] = useState(false);
    const [isGetTonSheetOpen, setIsGetTonSheetOpen] = useState(false);
    const [isClaimTonCustomModal, setIsClaimTonCustomModal] = useState(false);
    const [showOnboarding, setShowOnboarding] = useState(() => {
        const hasSeenOnboarding = localStorage.getItem('hasSeenOnboarding');
        return !hasSeenOnboarding;
    });
    const { tonClient  } = useTonClient();
    const { tonAddress, connected } = useTonConnect();
    const { fetchNfts } = useNft();
    
    const slides = [
        { component: <CoinDrive
             onBoostClick={() => setIsBoostSheetOpen(true)}
             onGetTonClick={() => setIsGetTonSheetOpen(true)} 
             onClaimTonClick={() => setIsClaimTonCustomModal(true)}
             /> },
        { component: <DailyStatsChart /> }, 
        // { component: <BaseSBT  imageUrl={user_photo_url} name={user.user.username ? ( user.user.username ) : ( user.user.first_name + ' ' + user.user.last_name )}  contractBalance="100" attributes={[]} /> }
    ];

    const language = localStorage.getItem('language_code') || 'en';
    const content = Getcontext( {language:language, contextKey:'main'});

    // Минимальное расстояние свайпа для переключения
    const minSwipeDistance = 50;

    const onTouchStart = (e) => {
        setTouchEnd(null);
        setTouchStart(e.targetTouches[0].clientX);
    };
   
    const onTouchMove = (e) => {
        setTouchEnd(e.targetTouches[0].clientX);
    };

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return;
        
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;
        
        if (isLeftSwipe && activeSlide < slides.length - 1) {
            setActiveSlide(prev => prev + 1);
        }
        if (isRightSwipe && activeSlide > 0) {
            setActiveSlide(prev => prev - 1);
        }
    };
    useEffect(() => {
        if (connected && nfts.length === 0) {
            fetchNfts();
        }
       
    }, [connected]);


    const handleOnboardingComplete = () => {
        localStorage.setItem('hasSeenOnboarding', 'true');
        setShowOnboarding(false);
    };
    
    return (
        <>
            {showOnboarding ? (
                <Onboarding onComplete={handleOnboardingComplete} style={{zIndex: 1000}} />
            ) : (
                <div className=" relative min-h-[100dvh] bg-gradient-to-b from-[#1a0b2e] to-[#0f0618] text-white p-4 ">
                    <div><TelegramAccount /></div>
                    
                    <div className="relative ">
                        <div 
                            className="overflow-hidden touch-pan-y"
                            ref={slideContainerRef}
                            onTouchStart={onTouchStart}
                            onTouchMove={onTouchMove}
                            onTouchEnd={onTouchEnd}
                        >
                            <div 
                                className="flex transition-transform duration-300 ease-in-out" 
                                style={{ transform: `translateX(-${activeSlide * 100}%)` }}
                            >
                                {slides.map((slide, index) => (
                                    <div key={index} className="w-full flex-shrink-0">
                                        {slide.component}
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* Навигационные точки */}
                        <div className="flex justify-center gap-1 ">
                            {slides.map((_, index) => (
                                <button
                                    key={index}
                                    onClick={() => setActiveSlide(index)}
                                    className={`w-2 h-2 rounded-full transition-all ${
                                        activeSlide === index 
                                            ? 'bg-violet-600 w-4' 
                                            : 'bg-gray-600'
                                    }`}
                                    aria-label={`Слайд ${index + 1}`}
                                />
                            ))}
                        </div>

                        {/* Заголовки */}
                        <div className="flex justify-center  mb-4">
                            {slides.map((slide, index) => (
                                <button
                                    key={index}
                                    onClick={() => setActiveSlide(index)}
                                    className={`text-sm ${
                                        activeSlide === index 
                                            ? 'text-violet-400' 
                                            : 'text-gray-400'
                                    }`}
                                >
                                    {slide.title}
                                </button>
                            ))}
                        </div>
                    </div>
                    
                    <InfoCards cards={content}  />
                    
                    <br/><br/>
                    <br/><br/>
                    <br/>
                    <br/>
                    <div>
                    {/* <ToggleSwitch /> */}
                    {/* <SwiperComponent cards={cards} /> */}
                    </div>
                    {/* <div><CoinDrive /></div> */}
                    
                    {/* Модальное окно бустеров - перемещаем выше в разметке */}
                    <BoosterBottomSheet 
                        isOpen={isBoostSheetOpen} 
                        onClose={() => setIsBoostSheetOpen(false)}
                        style={{ bottom: 'env(safe-area-inset-bottom)' }}
                    >
                        <div className="max-h-[70vh] overflow-y-auto">
                            <div className="p-6 text-center pb-[calc(env(safe-area-inset-bottom)+24px)]">
                                <h3 className="text-xl font-medium text-white mb-4">Бустеры</h3>
                                
                                <div className="space-y-4">
                                    <div className="bg-gradient-to-r from-[#2e1065]/50 to-[#4c1d95]/50 p-4 rounded-lg">
                                        <div className="flex items-center justify-between mb-2">
                                            <div className="flex items-center gap-2">
                                                <img src={require(`../img/zzz.png`)} alt="boost" className='w-6 h-6' />
                                                <span className="text-white">x1</span>
                                            </div>
                                            <span className="text-gray-400">Активен</span>
                                        </div>
                                        <p className="text-sm text-gray-400 text-left">
                                            Базовый множитель начисления поинтов
                                        </p>
                                    </div>

                                    <div className="bg-gradient-to-r from-[#2e1065]/50 to-[#4c1d95]/50 p-4 rounded-lg opacity-50">
                                        <div className="flex items-center justify-between mb-2">
                                            <div className="flex items-center gap-2">
                                                <img src={require(`../img/zzz.png`)} alt="boost" className='w-6 h-6' />
                                                <span className="text-white">x2</span>
                                            </div>
                                            <span className="text-gray-400">Заблокирован</span>
                                        </div>
                                        <p className="text-sm text-gray-400 text-left">
                                            Удвоенное начисление поинтов
                                        </p>
                                    </div>

                                    <div className="bg-gradient-to-r from-[#2e1065]/50 to-[#4c1d95]/50 p-4 rounded-lg opacity-50">
                                        <div className="flex items-center justify-between mb-2">
                                            <div className="flex items-center gap-2">
                                                <img src={require(`../img/zzz.png`)} alt="boost" className='w-6 h-6' />
                                                <span className="text-white">x3</span>
                                            </div>
                                            <span className="text-gray-400">Заблокирован</span>
                                        </div>
                                        <p className="text-sm text-gray-400 text-left">
                                            Тройное начисление поинтов
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </BoosterBottomSheet>
                    <ToastContainer          
        position="top-center"
        autoClose={2000}        
        theme="dark"  
        // toastClassName={({ type }) => 
        //     type === 'success' 
        //         ? 'bg-gray-500 rounded-lg'
        //         : undefined
        // }      
        /> 
                    <CustomModalClaimTon 
                        isOpen={isGetTonSheetOpen} 
                        onClose={() => setIsGetTonSheetOpen(false)}
                       
                    >
                        <GetTonModal  onClose={() => setIsGetTonSheetOpen(false)} />

                    </CustomModalClaimTon>
                    
                    <CustomModalClaimTon isOpen={isClaimTonCustomModal} onClose={() => setIsClaimTonCustomModal(false)}>


                    <SwapTonInput maxBalance={ton_balance} onClose={() => setIsClaimTonCustomModal(false)} />
                    </CustomModalClaimTon>

                    {/* Нижняя навигация и другие элементы */}
                    <div style={styles.touchableOpacity}>
                        <BreathingButton />
                    </div>
                    {/* <ImageComponent /> */}
                    {/* <div style={styles.conteynerimg}>
                        <img src={require('../img/centr.png')} alt="telegram" style={styles.img}    />
                    </div> */}
                </div>
            )}
        </>
    );
};

const styles = {
    touchableOpacity: {
        margin: '0 auto', // Adjust this value to position above the tab navigation
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    button: {
        position: 'fixed',
        left: '20px',
        right: '20px',
        bottom: '100px',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    },
    img: {
        borderRadius:"60%",
        Overlay: 'hidden',
    },
    conteynerimg:{
        padding:"10px",
        display:"flex",
        justifyContent: 'center',
        alignItems:"center",
        margin: "0 100px 0px 100px",padding: '30px'
    }
};

export default Main;
