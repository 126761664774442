import React, { useEffect, useState, useContext } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { BottomNav } from './components/BottomTabNavigatorTailwin.js';
import { UserContext } from './UserContext.js';
import UserLoader from './UserLoader.js';
import  Preload  from './components/preload';
import { TonConnectUIProvider , THEME} from '@tonconnect/ui-react';
import SessionManager from './components/SessionManager';

function App() {
  
  const { setUser , user, setDistance} = useContext(UserContext);
  let [initdata1, setInitdata1] = useState(null);
  
  function extractStartParam(initDataString) {
    try {
      // Создаем объект URLSearchParams из строки
      const params = new URLSearchParams(initDataString);
  
      // Извлекаем параметр 'start_param'
      const startParam = params.get('start_param');
      if (startParam) {
        console.log('Параметр старта:', startParam);
        return startParam;
      } else {
        console.error('Параметр "start_param" не найден.');
        return null;
      }
    } catch (error) {
      console.error('Ошибка при извлечении параметра:', error);
      return null;
    }
  }
  
  useEffect(() => {
    let attempts = 0;
    const maxAttempts = 5; // Максимальное количество попыток
    const intervalTime = 2000; // Интервал между попытками в миллисекундах

   async function applyTelegramStyles() {
      console.log("Проверка наличия Telegram API...");

      const tg = window.Telegram;
      console.log(tg);
      if (tg && tg.WebApp) {
        console.log("Telegram API доступен, инициализация...");      
       let initdata1 = window.Telegram.WebApp.initData;
        // if (initdata1) {
        //    const startParam = extractStartParam(initdata1);
        //   setInitdata1(startParam);
        //   console.log("initdata1", initdata1);
        //  }


        
        const initData = tg.WebApp.initDataUnsafe;
        if (initData && initData.user && initData.user.id && !user && initData.hash ) {
          console.log("Приложение запущено в Telegram.");
          console.log(tg.WebApp);
          console.log("Window", window);


          if (tg.WebApp) {
            // Закрываем QR-сканер если открыт
            
            
            // Устанавливаем стили после проверки
            tg.WebApp.setBackgroundColor('#1a0b2e');
            tg.WebApp.setBottomBarColor("#1a0b2e");
            tg.WebApp.setHeaderColor('#000000');
            tg.WebApp.disableVerticalSwipes();
            console.log("tg.WebApp.isFullscreen()", tg.WebApp);
            
            tg.WebApp.ready(); 
            tg.WebApp.expand();

            // Безопасно закрываем другие сессии после инициализации
          

            setTimeout(() => {
              setUser(initData);
              return true;
            }, 3000);

            
            if (initData.start_param) {
              let startParam = initData.start_param;
              console.log("startParam 1", startParam);
              
              if (startParam.includes('mili_')) {
                let mili = startParam.split("_")[1];
                mili = mili.replace("n", ".");
                console.log("mili", mili);
                setDistance((later) => later + parseFloat(mili));
              }
            }
          } else {
            console.log('Параметр старта:', false);
           
          }
           
           // Успешная инициализация
        } else {
          console.log("Приложение запущено не в Telegram.");
        }
      } else {
        console.error('Telegram WebApp API недоступен.');
      }
      return false; // Неуспешная инициализация
    }

    const interval = setInterval(() => {
      if (applyTelegramStyles() || attempts >= maxAttempts) {
        clearInterval(interval);
      }
      attempts++;
    }, intervalTime);

    // Очистка интервала при размонтировании компонента
    return () => clearInterval(interval);
  }, [user]);

  

  return (
    
    <TonConnectUIProvider manifestUrl="https://wintext.ru/manifest.json" 
    uiPreferences={{theme: THEME.DARK}}
    walletsListConfiguration={{
      includeWallets: [
        {
          appName: "DriveCoin",
          name: "DriveCoin",
          imageUrl: "https://wintext.ru/icon.png",
          aboutUrl: "https://wintext.ru",
          jsBridgeKey: "drivecoin",
          platforms: ["ios", "android", "web", "tizen", "tizenmobile"]
        }
      ]
    }}
    actionsConfiguration={{
      returnStrategy: "back",
      twaReturnUrl: 'https://t.me/Drive_Coin_Official_bot/DriveCoin',
      modals: ['before', 'error'],
      notifications: 'all'
    }}
    
    > 

    <Router>
      <SessionManager />
      <Preload />    
      <UserLoader />    
      <BottomNav />
    </Router>
    </TonConnectUIProvider>
    
  );  
}

export default App;
