import React, { useState, useEffect, useContext } from 'react';

import { UserContext } from '../UserContext.js';

import { Gauge } from 'lucide-react';
import Getcontext from './Getcontext.js';
import DailyStatsChart from './DailyStatsChart.js';
import { CustomModalReact ,CustomBottomSheet} from './CustomModalReact.js';
import { useContractMint } from '../Hook/useContractNft';
import { toast } from 'react-toastify';
import useNft from '../Hook/useNft.ts';
import {useTonConnect} from '../Hook/useTonconnect.ts';     



function CoinDrive({ onBoostClick , onGetTonClick, onClaimTonClick}) {
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
   
  const [data, setData] = useState([]);
  const { distance, setDistance, total_distance,   setTotal_distance , user, setUser, ton_balance, setTon_balance, ton_adress_sbt, setTon_adress_sbt } = useContext(UserContext);
  //if (!user) {setUser({id: 5000535804})}
  const [isBoostSheetOpen, setIsBoostSheetOpen] = useState(false);
  const element = Getcontext({ language: localStorage.getItem('language_code') || 'en', contextKey: 'element' });
  const { сlaimTonSbt } = useContractMint();
  const {sender, connected, wallet, network, tonAddress ,  tonConnectUI, tonConnectModal} = useTonConnect();
  const {fetchNfts} = useNft();
  useEffect(() => {
    setTimeout(() => {
      fetchNfts();
  }, 1000);
}, []);
  
  const [tonPrice, setTonPrice] = useState(0);

  useEffect(() => {
    const fetchTonPrice = async () => {
      try {
        const response = await fetch('https://tonapi.io/v2/rates/markets');  
        const data = await response.json();
        
        // Проверяем наличие данных с бирж
        if (data.markets && data.markets.length > 0) {
          // Вычисляем среднюю цену по всем биржам
          const totalPrice = data.markets.reduce((sum, market) => sum + market.usd_price, 0);
          const averagePrice = totalPrice / data.markets.length;
          setTonPrice(averagePrice);
          
          // Альтернативно можно использовать конкретную биржу, например Bybit
          // const bybitData = data.markets.find(market => market.market === 'Bybit');
          // if (bybitData) setTonPrice(bybitData.usd_price);
        } else {
          console.error('No market data available');
        }
      } catch (error) {
        console.error('Error fetching TON price:', error);
      }
    };
    fetchTonPrice();
  }, []);

  return (
    <> 
      <div className="mb-6  h-[180px] overflow-hidden rounded-2xl bg-gradient-to-br from-[#2e1065] to-[#4c1d95] p-6 shadow-[0_0_15px_rgba(139,92,246,0.3)]">
        <div className="mb-4 flex items-center justify-between">

            <h3 className="text-lg font-medium text-gray-200">Drive Coin Dashboard</h3>
          
          <Gauge className="h-6 w-6 text-violet-400" />
        </div>
        <div className="text-4xl font-bold text-white"> <span className="text-sm text-gray-400 mr-2"><img src={require(`../img/dp.png`)} alt="dp" className='w-8 h-8 mb-[20px] inline-block' /></span>
        

        {total_distance ? total_distance.toFixed(0) : '0.00'}</div>
        <div className="text-4xl font-bold text-white flex items-center justify-between">
          <div className="flex items-center">
            <span className="text-sm text-gray-400 mr-2">
              <img src={require(`../img/ton.png`)} alt="dp" className='w-8 h-8 inline-block bg-white rounded-full' />
            </span>
            <div className="flex items-baseline">
              <span>{ton_balance > 0 ? ton_balance.toFixed(2) : '0.00'}</span>
              <span className='text-sm text-gray-400 ml-2'> ~{tonPrice > 0 ? (ton_balance * tonPrice).toFixed(2) + '$' : ''}</span>
            </div>
          </div>
           {!ton_balance  && (<button  

              onClick={onGetTonClick}

              className="px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-violet-600 to-violet-700 rounded-lg 

              hover:from-violet-500 hover:to-violet-600 

              transition-all duration-300 

              shadow-[0_0_10px_rgba(139,92,246,0.3)]

              hover:shadow-[0_0_15px_rgba(139,92,246,0.4)]

              border border-violet-500/20 

              hover:border-violet-400/30

              backdrop-blur-sm

              flex items-center gap-2"

          >

              <span>How earn TON</span> 
          </button>

          )}

          {ton_balance > 0 && (

            <button  

            onClick={onClaimTonClick}

            className="px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-violet-600 to-violet-700 rounded-lg 

            hover:from-violet-500 hover:to-violet-600 

            transition-all duration-300 

            shadow-[0_0_10px_rgba(139,92,246,0.3)]

            hover:shadow-[0_0_15px_rgba(139,92,246,0.4)]

            border border-violet-500/20 

            hover:border-violet-400/30

            backdrop-blur-sm

            flex items-center gap-2"

        >

            <span>Claim TON</span>

          </button>

          )}



        </div>
        
        
        {/* <div 
          className="mt-2 text-sm text-gray-400 cursor-pointer hover:text-gray-300 transition-colors flex items-center gap-1"
          onClick={onBoostClick}
        >
          {element.total_boost}
          <img src={require(`../img/zzz.png`)} alt="boost" className='w-4 h-4' />
          <span className="ml-1">x1</span>
         
        </div> */}
        
      

      </div>
      
            

        
       
           
        
      
</>

  );
}

const styles = {
  container: {
    position: 'relative',
    width: '100%',  
    alignItems: 'center',
   padding: '50px',
   
  },
  price: {
    fontSize: '24px',
  fontWeight:"700",
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  
 
    
  },
  title: {

    fontSize: '24px',
    color: 'white',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
 
  imgcontainer: {
   position: 'absolute',
   left: '0',
   right: '0',
   
   
  },
  img: {
 borderRadius: '50%',
 border: '2px solid #0085fa',
 boxShadow: ' 0 0 20px #0085fa, 0 0 40px #0085fa ',

},
  priceicon: {
    color: "white",
    fontSize: "24px",
    marginRight: "10px"
  },
  container1: {
    color: "yellow",
    textAlign: "center",   
    marginTop: "10px", 
    fontWeight: "bold",
  },
  lvl: {
    color: "white",
    fontWeight: "bold",
    fontSize: "18px"
  },
  lvlcontainer: {
    lineHeight: "34px",
    position: "relative",    
    color: "white",
    fontWeight: "normal",
    fontSize: "16px", 
    display: "flex",
    justifyContent: "left",     
    border: "0px solid white",
    borderRadius: "25px",
    padding: "0px 25px", 
    width: "fit-content",
   
  },
  '@keyframes breathing': {
    '0%': { boxShadow: '0 0 10px #0085fa' },
    '50%': { boxShadow: '0 0 40px #0085fa' },
    '100%': { boxShadow: '0 0 10px #0085fa' },
  },
  breathingGlow: {
    animation: 'breathing 7s infinite',
  },
};

export default CoinDrive;
