import React, { useState, useEffect } from 'react';
import { Button, Image } from 'react-bootstrap';
import { ChevronRight } from 'lucide-react';
import { CustomModalTask } from './CustomModalReact.js';
import TaskChecker from './TaskChecker';


function ListGroupItem({ items }) {
  const [modalStates, setModalStates] = useState(() => 
    items.reduce((acc, _, index) => ({
      ...acc,
      [index]: false
    }), {})
  );
  const [activeTasks, setActiveTasks] = useState([]);
  const [completedTasks, setCompletedTasks] = useState([]);

  const updateTaskLists = () => {
    const completedTaskIds = JSON.parse(localStorage.getItem('completedTasks') || '[]');
    const active = items.filter(item => !completedTaskIds.includes(item.id));
    const completed = items.filter(item => completedTaskIds.includes(item.id));
    
    setActiveTasks(active);
    setCompletedTasks(completed);
  };

  useEffect(() => {
    updateTaskLists();
  }, [items]);

  const handleTaskSuccess = (taskId, reward) => {
    setIsModalOpen(taskId, false);
    updateTaskLists(); // Обновляем списки сразу после выполнения
  };

  const setIsModalOpen = (index, value) => {
    setModalStates(prevStates => ({
      ...prevStates,
      [index]: value
    }));
  };

  return (
    <>
      <div className="space-y-4">
        <div className='flex flex-col items-left justify-center text-gray-400'>Активные задачи</div>
        {activeTasks.map((item, index) => (
          <div
            onClick={() => setIsModalOpen(index, true)}
            key={item.id}
            className="overflow-hidden border-0 bg-gradient-to-br from-[#2e1065]/50 to-[#4c1d95]/50 shadow-[0_0_15px_rgba(139,92,246,0.15)] p-2 rounded-lg"
          >
            <div className="flex items-start">
              <Image
                src={item.img ? require('../img/tasks/' + item.img) : ''}
                roundedCircle
                className="w-12 h-12 object-cover mr-4 rounded-full"
              />
              <div>
                <h2 className="text-md text-white">{item.title} </h2>
                {item.text && <p className="text-sm text-gray-400">{item.text}</p>}
              </div>
              <div className='ml-auto flex items-center justify-end min-w-[80px]'>
                <div className='flex items-center gap-1 bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] text-white rounded-lg px-2 py-1.5 shadow-lg shadow-indigo-500/20'>
                  <span className='text-gray-200 text-sm font-medium whitespace-nowrap'>+{item.reward}</span>
                  <img 
                    src={require('../img/dp.png')} 
                    alt="dp" 
                    className="w-3.5 h-3.5 opacity-90" 
                  />
                </div>
              </div>
            </div>
          </div>
        ))}

        

        {activeTasks.map((item, index) => (
          <CustomModalTask
            key={`modal-${index}`}
            isOpen={modalStates[index]}
            onClose={() => setIsModalOpen(index, false)}
          >
            <div className='flex flex-col items-center justify-center h-full max-w-sm mx-auto px-4 py-6'>
              <div className='flex flex-col items-center justify-center'>
                <Image 
                  src={item.img ? require('../img/tasks/' + item.img) : ''} 
                  roundedCircle 
                  className="w-20 h-20 object-cover rounded-full" 
                />
              </div>

              <div className='flex items-center gap-1 mt-6 bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] text-white rounded-lg px-3 py-1.5 shadow-lg shadow-indigo-500/20'>
                <span className='text-gray-200 text-md font-medium whitespace-nowrap'>+{item.reward}</span>
                <img 
                  src={require('../img/dp.png')} 
                  alt="dp" 
                  className="w-3.5 h-3.5 opacity-90" 
                />
              </div>

              <h2 className='text-gray-400 text-center my-6'>{item.desk}</h2>

              <div className='w-full'>
                <TaskChecker 
                  taskType={item.type}
                  taskData={{
                    channelUsername: item.channelUsername,  
                    channelId: item.channelId,  
                    accountName: item.accountName,  
                    reward: item.reward,  
                    link: item.link,  
                    text_button: item.text_button, 
                    id: item.id
                  }}
                  onSuccess={() => handleTaskSuccess(index, item.reward)}
                />
              </div>
            </div>
          </CustomModalTask>
        ))}

        <div className='flex flex-col text-left text-gray-400 items-left justify-center mt-4'>
          Выполненные задачи
        </div>
        {completedTasks.map((item, index) => (
          <div
            key={item.id}
            className="overflow-hidden border-0 bg-gradient-to-br from-[#2e1065]/50 to-[#4c1d95]/50 shadow-[0_0_15px_rgba(139,92,246,0.15)] p-2 rounded-lg opacity-50"
          >
            <div className="flex items-start">
              <Image
                src={item.img ? require('../img/tasks/' + item.img) : ''}
                roundedCircle
                className="w-12 h-12 object-cover mr-4 rounded-full"
              />
              <div>
                <h2 className="text-md text-white">{item.title}</h2>
                {item.text && <p className="text-sm text-gray-400">{item.text}</p>}
              </div>
              <div className="text-green-500 ml-auto text-bold">✓</div>
            </div>
          </div>

        ))}
       
      </div>
       <div className='flex flex-col text-left text-gray-400 items-left justify-center mt-4'>
         Партнерские задачи
        </div>
      <div  
        onClick={() => window.open('https://t.me/drivecoin_community', '_blank')}          
        className="overflow-hidden mt-4 relative group rounded-lg mb-10"
      >
        {/* Фоновый градиент с анимацией */}
        <div className="absolute inset-0 bg-gradient-to-r from-purple-600/20 via-violet-800/20 to-purple-600/20 animate-gradient-x"></div>
        
        {/* Основной контент с градиентной подложкой */}
        <div className="relative p-4 bg-gradient-to-br from-[#2e1065]/80 to-[#4c1d95]/80 backdrop-blur-sm rounded-lg border border-white/5 shadow-[0_0_15px_rgba(139,92,246,0.15)] hover:shadow-[0_0_25px_rgba(139,92,246,0.25)] transition-all duration-500">
          <div className="absolute inset-0 bg-gradient-to-r from-violet-500/10 via-fuchsia-500/10 to-violet-500/10 opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
          
          <div className="relative flex items-center">
            <div className="relative">
              <div className="absolute inset-0 bg-gradient-to-r from-violet-500 to-fuchsia-500 rounded-full blur-md opacity-50 group-hover:opacity-75 transition-opacity duration-500"></div>
              <Image
                src={require('../img/tasks/logo_tasks.png')}
                roundedCircle
                className="relative w-12 h-12 object-cover rounded-full ring-2 ring-violet-500/20 group-hover:ring-violet-400/40 transition-all duration-500"
              />
              <div className="absolute -bottom-1 -right-1 bg-gradient-to-r from-violet-500 to-fuchsia-500 text-white text-xs px-2 py-0.5 rounded-full shadow-lg">
                AD
              </div>
            </div>
            
            <div className="ml-4 flex-grow">
              <h2 className="text-md  text-white group-hover:text-violet-200 transition-colors duration-500">
                Здесь может быть ваша реклама
              </h2>
              <p className="text-sm text-gray-400 group-hover:text-violet-300 transition-colors duration-500 flex items-center gap-2">
                Перейти в канал 
                <svg 
                  className="w-4 h-4 transform group-hover:translate-x-1 transition-transform duration-300" 
                  fill="none" 
                  stroke="currentColor" 
                  viewBox="0 0 24 24"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={2} 
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </p>
            </div>

            <div className="ml-auto">
              <div className="bg-white/5 p-2 rounded-full group-hover:bg-white/10 transition-colors duration-500 backdrop-blur-sm">
                <svg 
                  className="w-5 h-5 text-violet-300" 
                  fill="none" 
                  stroke="currentColor" 
                  viewBox="0 0 24 24"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={2} 
                    d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ListGroupItem;
