import React, { useState, useContext } from 'react';
import { UserContext, star } from '../UserContext.js';
import CryptoJS from 'crypto-js';


const TaskChecker = ({ taskType, taskData, onSuccess }) => {
    const [isChecking, setIsChecking] = useState(false);
    const { user, createStars, device_id, setDistance ,cards, clubs} = useContext(UserContext);

    const encryptData = (miles, deviceId) => {
        const timestamp = Date.now();
        const dataString = `${miles}:${deviceId}:${timestamp}`;
        const encrypted = CryptoJS.AES.encrypt(dataString, 'your-secret-key-here').toString();
        return encrypted;
    };

    const updateDistanceExample = async (miles) => {
        const encryptedData = encryptData(miles, device_id);
      
        try {
          const response = await fetch('https://drivecoin.io/api/device-operations', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              operation: 'updateDistance',
              deviceId: device_id,
              encryptedData: encryptedData,             
              user_id_tg: user.user.id
            })
          });
      
          const result = await response.json();
          console.log('Результат:', result);
        } catch (error) {
          console.error('Ошибка:', error);
        }
    };

    const getLinkClicks = (taskId) => {
        const clicks = localStorage.getItem(`task_clicks_${taskId}`);
        return clicks ? parseInt(clicks) : 0;
    };

    const incrementLinkClicks = (taskId) => {
        const currentClicks = getLinkClicks(taskId);
        localStorage.setItem(`task_clicks_${taskId}`, currentClicks + 1);
    };

    const handleLinkClick = () => {
        incrementLinkClicks(taskData.id);
        window.open(taskData.link, '_blank');
    };

    const checkTelegramSubscription = async (channelUsername) => {
        try {
            const response = await fetch('https://drivecoin.io:3000/check-telegram-subscription', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                   "userId": user.user.id,
                   "channelUsername": channelUsername
                })
            });
            
            const data = await response.json();           
            return data.isSubscribed;
        } catch (error) {
            window.Telegram.WebApp.showAlert('error');
            console.error('Telegram check error:', error);
            return false;
        }
    };

    const checkTask = async () => {
        setIsChecking(true);
        try {
            let isCompleted = false;

            switch (taskType) {
                case 'telegram':
                    isCompleted = await checkTelegramSubscription(taskData.channelUsername);
                    break;
                case 'youtube':
                case 'twitter':
                case 'link':
                    const clicks = getLinkClicks(taskData.id);
                    isCompleted = clicks >= 2;
                    break;
                case 'connect':
                    isCompleted = await checkConnect(taskData.link);
                    break;
                case 'frend_3':
                    isCompleted = await checkFrend_3(taskData.link);
                    break;
                    case 'frend_10':
                    isCompleted = await checkFrend_10(taskData.link);
                    break;
                    case 'frend_20':
                    isCompleted = await checkFrend_20(taskData.link);
                    break;
                    case 'clubs_join':
                    isCompleted = await checkClubsJoin(taskData.link);
                    break;
                default:
                    console.error('Unknown task type');

            }

            if (isCompleted) {
                try {
                    const completedTasks = localStorage.getItem('completedTasks');
                    const tasksArray = completedTasks ? JSON.parse(completedTasks) : [];
                    tasksArray.push(taskData.id);
                    localStorage.setItem('completedTasks', JSON.stringify(tasksArray));
                    
                    updateDistanceExample(parseFloat(taskData.reward));
                    createStars();
                    setDistance((prev) => prev + parseFloat(taskData.reward));
                    
                    onSuccess && onSuccess(taskData.reward);
                    
                    window.Telegram.WebApp.showAlert('Задание выполнено успешно! +' + taskData.reward + ' dp');
                } catch (error) {
                    console.error('Ошибка сохранения задания:', error);
                }
            } else {
                window.Telegram.WebApp.showAlert('Пожалуйста, убедитесь что вы выполнили задание');
            }
        } catch (error) {
            console.error('Error checking task:', error);
            window.Telegram.WebApp.showAlert('Произошла ошибка при проверке задания');
        } finally {
            setIsChecking(false);
        }
    };

    const checkYoutubeSubscription = async (channelId) => {
        const completedTasks = localStorage.getItem('completedTasks');
        const tasksArray = completedTasks ? JSON.parse(completedTasks) : [];
        tasksArray.push(taskData.id);
        localStorage.setItem('completedTasks', JSON.stringify(tasksArray));
        return false;
    };

    const checkTwitterSubscription = async (accountName) => {
        const completedTasks = localStorage.getItem('completedTasks'); 
        const tasksArray = completedTasks ? JSON.parse(completedTasks) : [];
        tasksArray.push(taskData.id);
        localStorage.setItem('completedTasks', JSON.stringify(tasksArray));
        return false;
    };

    const checkConnect = async (link) => {
        if(device_id !== null){
          return true;  
        }
        return false;
    };
    const checkClubsJoin = async () => {
        if(clubs > 0){
          return true;  
        }
        return false;
    };



    const checkFrend_3 = async () => {
      //alert(cards.filter(card => card.level === "Level 2").length);
        if(cards.filter(card => card.level === "Level 1").length >= 3){
         
          return true;  
        }

        return false;
    };
    const checkFrend_10 = async () => {
        //alert(cards.filter(card => card.level === "Level 2").length);
          if(cards.filter(card => card.level === "Level 1").length >= 10){
           
            return true;  
          }
  
          return false;
      };
      const checkFrend_20 = async () => {
        //alert(cards.filter(card => card.level === "Level 2").length);
          if(cards.filter(card => card.level === "Level 1").length >= 20){
           
            return true;  
          }
  
          return false;
      };


    return (
        <div className="flex flex-col justify-center items-center gap-4 w-full">
            {taskData.text_button && (<button
                onClick={['youtube', 'twitter', 'link'].includes(taskType) ? handleLinkClick : () => window.open(taskData.link, '_blank')}
                className="bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] text-white rounded-lg p-2 w-3/4"
            >
                {taskData.text_button}
            </button>
            )}
            <button
                onClick={checkTask}
                disabled={isChecking}
                className={`bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] text-white rounded-lg p-2 w-3/4 ${
                    isChecking ? 'opacity-50 cursor-not-allowed' : ''
                }`}
            >
                {isChecking ? 'Проверяем...' : 'Проверить'}
            </button>
        </div>
    );
};

export default TaskChecker; 