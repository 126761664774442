import React, { useContext, useState, useEffect } from 'react';
import Image from 'react-bootstrap/Image';
import { Users, User } from 'lucide-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserContext } from '../UserContext.js';
import CustomModalReact, { CustomModalReactFriend } from './CustomModalReact.js';
import { createStars } from './ConfettiEffect.js';
import Getcontext from './Getcontext.js';

function Friend() {
  const {  walletAddress, user, cards, setPage, loading, totalReferrals, referralsclaim, setReferralsclaim, setTotal_distance, total_distance } = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);
  const language = localStorage.getItem('language_code') || 'en';
  const content = Getcontext({ language: language, contextKey: 'friends' });
  
  const getCachedImage = (url) => {
    const cachedImage = localStorage.getItem(url);
    if (cachedImage) {
      return cachedImage;
    }
    return url;
  };

  const cacheImage = (url) => {
    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.blob();
      })
      .then(blob => {
        const reader = new FileReader();
        reader.onloadend = () => {
          localStorage.setItem(url, reader.result);
        };
        reader.readAsDataURL(blob);
      })
      .catch(error => {
        console.error('Ошибка при загрузке изображения:', error);
      });
  };

  useEffect(() => {
    console.log(cards);
    cards.forEach(card => {
      if (card.img) {
        cacheImage(card.img);
      }
    });
  }, [cards]);

  const handleInviteClick = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const copyReferralLink = () => {
    const referralLink = `https://t.me/Drive_Coin_Official_bot/DriveCoin?startapp=ref_${user.user.id}`;
    navigator.clipboard.writeText(referralLink)
      .then(() => {
        toast.info(content.copy_success, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      })
      .catch(err => {
        toast.error(content.copy_error + err);
      });
  };

  const openTelegramShare = () => {
    const referralLink = `https://t.me/Drive_Coin_Official_bot/DriveCoin?startapp=ref_${user.user.id}`;
    const shareText = encodeURIComponent('\n🚗 Привет! Я использую DriveCoin! 💰\nЭто приложение, начисляет монеты за каждый километр пути. Присоединяйся и начни получать вознаграждение за свои поездки! 🔥');
    


    window.Telegram.WebApp.openTelegramLink(`https://t.me/share/url?url=${referralLink}&text=${shareText}`);
  };

  const updateDistanceRef = () => {
    if (!referralsclaim) return;
    fetch(`https://drivecoin.io/api/update-distance-ref/${user.user.id}`)
        .then(response => response.json())
        .then(data => {
            // Явно указываем тип 'referral' при сохранении
            try {
                const claims = JSON.parse(localStorage.getItem('claims') || '[]');
                claims.push({
                    date: new Date().toISOString(),
                    distance: parseFloat(referralsclaim),
                    type: 'referral'
                });
                localStorage.setItem('claims', JSON.stringify(claims));
                window.dispatchEvent(new Event('claimUpdated'));
            } catch (error) {
                console.error('Ошибка сохранения статистики рефералов:', error);
            }
            
            setTotal_distance(total_distance + referralsclaim);
            setReferralsclaim(0);
            createStars();
        });
  };

  return (
    <div className='min-h-[100dvh] bg-gradient-to-b from-[#1a0b2e] to-[#0f0618] text-white p-4' style={{ position: 'relative' }}>
      <ToastContainer /> 
      <div className='text-center text-2xl mb-2 mt-4'>{content.title}</div>
      <div className='text-center text-sm mb-4 text-gray-400'>{content.subtitle}</div>

      <div className="overflow-hidden rounded-2xl bg-gradient-to-br from-[#2e1065] to-[#4c1d95] p-6 shadow-[0_0_15px_rgba(139,92,246,0.3)]">
        <div className="mb-4 flex items-center justify-between">
          <h3 className="text-lg font-medium text-gray-200">Friends: {totalReferrals}</h3>
          <Users className="h-6 w-6 text-violet-400" />
        </div>
        <div className="text-4xl font-bold text-white">
           <span className='mr-2'><img src={require(`../img/dp.png`)} alt="dp" className='w-8 h-8 mb-[10px] inline-block' /></span>
           <span className='mr-2'>{referralsclaim} </span>
        </div>
        <button onClick={updateDistanceRef} className={`px-4 py-2 mt-2 text-sm font-medium  bg-gradient-to-r from-violet-600 to-violet-700 rounded-lg hover:from-violet-500 hover:to-violet-600 transition-all duration-300 shadow-[0_0_10px_rgba(139,92,246,0.3)] hover:shadow-[0_0_15px_rgba(139,92,246,0.4)] border border-violet-500/20 hover:border-violet-400/30 backdrop-blur-sm flex items-center gap-2 ${!referralsclaim ? 'text-gray-400 border-gray-400' : 'text-[#f59e0b] border-[#f59e0b]'}`}>
            <span>{content.claim_points}</span> 
        </button>    
      </div>

      <div className='text-center pl-2 pr-2 mb-5 mt-2 text-gray-400' style={{ fontSize: '12px' }}>
        {content.referral_info}
      </div>

      <button
        className="button-custom mb-4 rounded-3xl bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] shadow-lg p-2.5 shadow-indigo-500/50"
        onClick={handleInviteClick}
      >
        {content.invite_friend}
      </button>

      {!walletAddress ? (
        <div className='bg-gray-800 rounded-lg p-4 m-3 shadow-lg border border-gray-700'>
            <div className='text-center text-white flex flex-col items-center justify-center gap-2'>
                <svg className="w-6 h-6 text-yellow-500 mb-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span className='font-medium'>Для получения вознаграждения в Тон</span>
                <span className='text-yellow-500 font-bold'>подключите кошелек</span>
            </div>
        </div>
      ) : ""}

      <div className="space-y-2" style={{ marginBottom: '250px' }}>
        {cards.map((item, index) => (
          <div
            key={index}
            className="overflow-hidden border-0 bg-gradient-to-br from-[#2e1065]/50 to-[#4c1d95]/50 shadow-[0_0_15px_rgba(139,92,246,0.15)] p-2 rounded-lg"
          >
            <div className="flex items-start">
              {item.img ? (
                <Image
                  src={getCachedImage(item.img)}
                  roundedCircle
                  className="w-8 h-8 object-cover mr-4 rounded-full"
                />
              ) : (
                <div className="rounded-full bg-gradient-to-r mr-4 object-cover from-[#f97316] to-[#f59e0b]">
                  <User className="w-8 h-8 object-cover text-white rounded-full" />
                </div>
              )}
              <div>
                <h2 className="text-md text-white">{item.title !== "No" ? item.title : item.first_name}</h2>
                <p className="text-sm text-gray-400">{item.level}</p>
              </div>
              <div className='text-md text-white ml-auto pr-2 flex items-end'> 
                {item.distance ? item.distance : 0}
                <span className='text-sm text-gray-400 ml-1 flex items-end'>
                  <img src={require(`../img/dp.png`)} alt="dp" className='w-4 h-4 mb-[4px] ml-1' />
                </span>
              </div>
            </div>
          </div>
        ))}
        <div
          className="mt-2 text-sm font-bold text-center text-gray-400 rounded-lg p-1 cursor-pointer transition-colors duration-200"
          onClick={() => setPage(prevPage => prevPage + 1)}
        >
          <span className='border-b border-gray-400'>{content.show_more}</span>
        </div>
      </div>

      {loading && <div className="text-center text-white">{content.loading}</div>}

      <CustomModalReactFriend isOpen={showModal} onClose={handleClose}>
        <div className="absolute p-2 top-1/4 left-0 right-0 flex flex-col space-y-4 items-center w-full mt-4">
            {!walletAddress ? (
                <div className='text-center text-white flex items-center justify-center'>
                   Прежде чем пригласить друзей, подключите кошелек это необходимо чтобы вы получали вознаграждение.  
                </div>
            ) : (
                <>
                    <button onClick={copyReferralLink} className="bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] text-white rounded-lg p-2 w-3/4">
                        {content.copy_link}
                    </button>
                    <button onClick={openTelegramShare} className="bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] text-white rounded-lg p-2 w-3/4">
                        {content.share_telegram}
                    </button>
                </>
            )}
        </div>
      </CustomModalReactFriend>
    </div>
  );
}

export default Friend;
