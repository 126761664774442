import { useEffect, useState, useContext } from 'react';
import { motion } from 'framer-motion';
import { FaTelegramPlane, FaTwitter, FaYoutube } from 'react-icons/fa';
import { UserContext } from '../UserContext.js';

 export default function Preload() {
  const [isVisible, setIsVisible] = useState(true);
  const {user} = useContext(UserContext);

  useEffect(() => {
    const interval = setInterval(() => {
      if (user && user.user && user.user.id && user.hash) {
       setTimeout(() => {
        setIsVisible(false);
       }, 200);
        clearInterval(interval);
      }
    }, 200);

    return () => clearInterval(interval);
  }, [user]);

  if (!isVisible) return null;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 z-50 flex items-center justify-center"
    >
      <div 
        className="relative h-full z-60 w-full bg-cover bg-center"
        style={{ 
          backgroundImage: `url(${require(`../img/loading6.webp`)})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover'
        }}
      >
        <div className="absolute inset-0 bg-gradient-to-t from-[#1a0b2e]/90 via-transparent to-transparent" />
        
        <div className="absolute inset-0 flex flex-col items-center justify-center px-4 text-center"
             style={{ marginTop: '350px' }}
        >
          <motion.h1
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.8 }}
            className="mb-4 text-6xl font-bold tracking-tighter text-white"
            style={{ textShadow: '0 0 10px rgba(255, 255, 255, 0.5)' }}
          >
            <span className="bg-gradient-to-r from-violet-100 to-fuchsia-200 bg-clip-text text-transparent">
              Drive to Earn
            </span>
            
          </motion.h1>
          {/* <motion.p
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.8 }}
            className="mb-4 text-md font-bold tracking-tighter text-gray-300"
            style={{ textShadow: '0 0 10px rgba(255, 255, 255, 0.5)' }}
          >
            <span className=" font-light font-mono  text-md bg-gradient-to-r from-violet-100 to-fuchsia-200 bg-clip-text text-transparent">
              RWA Project On TON
            </span>
            
          </motion.p> */}
          
          <motion.p
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.8, duration: 0.8 }}
            className="text-xl font-semibold tracking-wide text-gray-300 "
            style={{ textShadow: '0 0 10px rgba(255, 255, 255, 0.5)' }}
          >
            World Community of Drivers
          </motion.p>

          <motion.p
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.8, duration: 0.8 }}
            className="text-md mt-2  font-light font-mono   tracking-wide text-gray-300 border-2 border-gray-300 rounded-md p-1 pl-2 pr-2 "
          >
           RWA Project On TON 
          </motion.p>
          <div className="flex space-x-4 mt-4">
            <a href="https://t.me/yourchannel" target="_blank" rel="noopener noreferrer">
              <FaTelegramPlane className="text-white h-8 w-8" />
            </a>
            <a href="https://x.com/yourprofile" target="_blank" rel="noopener noreferrer">
              <FaTwitter className="text-white h-8 w-8" />
            </a>
            <a href="https://youtube.com/yourchannel" target="_blank" rel="noopener noreferrer">
              <FaYoutube className="text-white h-8 w-8" />
            </a>
          </div> 
        </div>
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-transparent h-16 w-16 animate-spin"
               style={{ borderTopColor: '#3498db', borderRightColor: '#3498db', animation: 'spin 1s linear infinite, pulse 2s infinite' }}></div>
        </div>
        </div>
      
    </motion.div>
  );
}