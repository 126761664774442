import React, { useContext } from 'react';
import LoadClubs from './LoadClubs.js';
import Getcontext from './Getcontext.js';
import { UserContext } from '../UserContext.js';

function Clubs() { 
    const language = localStorage.getItem('language_code') || 'en';
    const content = Getcontext({ language: language, contextKey: 'clubs' });
    const { clubs }  = useContext(UserContext);
    return (<>
        <div className=' bg-gradient-to-b from-[#1a0b2e] to-[#0f0618] text-white p-4 z-1000'> 
                <div className='text-center text-2xl mb-2 mt-4 flex items-center justify-center gap-2'>
                 {content.title}
                 </div>
                 
           <LoadClubs myClub={clubs} /> 
           <div style={{height: '100px'}}></div>

        </div>
        
        </>
        
        

    );
}

export default Clubs;
