import React, { useState, useEffect, useContext } from 'react';
import { FaGasPump, FaCar, FaWater, FaTools } from 'react-icons/fa';
import { CustomModalReact } from './CustomModalReact';
import {UserContext} from '../UserContext';

const ProkaChka = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [serviceType, setServiceType] = useState('gas');
    const [days, setDays] = useState(1);
    const [pointsAmount, setPointsAmount] = useState(0);
    const [targetDate, setTargetDate] = useState('');
    const [maxBalance, setMaxBalance] = useState(100);
   
    const {total_distance} = useContext(UserContext);

    // Настройки для разных типов услуг
    const serviceConfig = {
        gas: { 
            dpPerDay: 10, 
            maxDays: 7, 
            title: "Заправка", 
            icon: <FaGasPump className="w-5 h-5 text-violet-300" /> 
        },
        wash: { 
            dpPerDay: 5, 
            maxDays: 14, 
            title: "Мойка", 
            icon: <FaWater className="w-5 h-5 text-violet-300" /> 
        },
        tire: { 
            dpPerDay: 1, 
            maxDays: 30, 
            title: "Шины", 
            icon: <FaCar className="w-5 h-5 text-violet-300" /> 
        },
        pump: { 
            dpPerDay: 5, 
            maxDays: 60, 
            title: "Насос", 
            icon: <FaTools className="w-5 h-5 text-violet-300" /> 
        }
    };

    // Открытие модального окна с выбранным сервисом
    const openModal = (type) => {
        setServiceType(type);
        setDays(1); // Начинаем с 1 дня
        updateCalculation(1, type);
        setIsOpen(true);
    };

    // Обновление расчета на основе дней и типа услуги
    const updateCalculation = (selectedDays, type) => {
        const config = serviceConfig[type];
        // Ограничиваем количество дней максимальным значением
        const limitedDays = Math.min(selectedDays, config.maxDays);
        // Рассчитываем количество DP
        const points = limitedDays * config.dpPerDay;
        setPointsAmount(points);
        
        // Рассчитываем дату, до которой будет продлена услуга
        const futureDate = new Date();
        futureDate.setDate(futureDate.getDate() + limitedDays);
        setTargetDate(futureDate.toLocaleDateString('ru-RU', {
            day: 'numeric',
            month: 'long'
        }));
    };

    return (
        <div className="flex justify-center items-center mb-2 p-2">
            <div className="grid grid-cols-4 gap-4 w-full max-w-md">
                <div className="flex flex-col items-center">
                    <div className="w-12 h-12 relative flex items-center justify-center">
                        {/* Круговой прогресс-бар как внешняя граница */}
                        <svg className="absolute w-full h-full" viewBox="0 0 100 100">
                            <circle 
                                className="text-violet-900/30 stroke-current" 
                                strokeWidth="4" 
                                cx="50" 
                                cy="50" 
                                r="46" 
                                fill="none"
                            ></circle>
                            <circle 
                                className="text-violet-400 stroke-current" 
                                strokeWidth="4" 
                                strokeLinecap="round" 
                                cx="50" 
                                cy="50" 
                                r="46" 
                                fill="none"
                                strokeDasharray="289.5"
                                strokeDashoffset="115.8"
                                transform="rotate(-90 50 50)"
                            ></circle>
                        </svg>
                        
                        {/* Внутренний фон круга */}
                        <div className="w-10 h-10 bg-gradient-to-br from-[#2e1065] to-[#4c1d95] rounded-full flex items-center justify-center z-10" onClick={() => openModal('gas')}>
                            {/* Иконка в центре */}
                            <FaGasPump className="w-5 h-5 text-violet-300" />
                        </div>
                    </div>
                    <span className="text-xs text-gray-400 mt-1">Gas 60%</span>
                </div>

                <div className="flex flex-col items-center">
                    <div className="w-12 h-12 relative flex items-center justify-center">
                        {/* Круговой прогресс-бар как внешняя граница */}
                        <svg className="absolute w-full h-full" viewBox="0 0 100 100">
                            <circle 
                                className="text-violet-900/30 stroke-current" 
                                strokeWidth="4" 
                                cx="50" 
                                cy="50" 
                                r="46" 
                                fill="none"
                            ></circle>
                            <circle 
                                className="text-violet-400 stroke-current" 
                                strokeWidth="4" 
                                strokeLinecap="round" 
                                cx="50" 
                                cy="50" 
                                r="46" 
                                fill="none"
                                strokeDasharray="289.5"
                                strokeDashoffset="173.7"
                                transform="rotate(-90 50 50)"
                            ></circle>
                        </svg>
                        
                        {/* Внутренний фон круга */}
                        <div className="w-10 h-10 bg-gradient-to-br from-[#2e1065] to-[#4c1d95] rounded-full flex items-center justify-center z-10" onClick={() => openModal('wash')}>
                            {/* Иконка в центре */}
                            <FaWater className="w-5 h-5 text-violet-300" />
                        </div>
                    </div>
                    <span className="text-xs text-gray-400 mt-1">Wash 40%</span>
                </div>

                <div className="flex flex-col items-center">
                    <div className="w-12 h-12 relative flex items-center justify-center">
                        {/* Круговой прогресс-бар как внешняя граница */}
                        <svg className="absolute w-full h-full" viewBox="0 0 100 100">
                            <circle 
                                className="text-violet-900/30 stroke-current" 
                                strokeWidth="4" 
                                cx="50" 
                                cy="50" 
                                r="46" 
                                fill="none"
                            ></circle>
                            <circle 
                                className="text-violet-400 stroke-current" 
                                strokeWidth="4" 
                                strokeLinecap="round" 
                                cx="50" 
                                cy="50" 
                                r="46" 
                                fill="none"
                                strokeDasharray="289.5"
                                strokeDashoffset="202.65"
                                transform="rotate(-90 50 50)"
                            ></circle>
                        </svg>
                        
                        {/* Внутренний фон круга */}
                        <div className="w-10 h-10 bg-gradient-to-br from-[#2e1065] to-[#4c1d95] rounded-full flex items-center justify-center z-10" onClick={() => openModal('pump')}>
                            {/* Иконка в центре */}
                            <FaTools className="w-5 h-5 text-violet-300" />
                        </div>
                    </div>
                    <span className="text-xs text-gray-400 mt-1">Pump 30%</span>
                </div>

                <div className="flex flex-col items-center">
                    <div className="w-12 h-12 relative flex items-center justify-center">
                        {/* Круговой прогресс-бар как внешняя граница */}
                        <svg className="absolute w-full h-full" viewBox="0 0 100 100">
                            <circle 
                                className="text-violet-900/30 stroke-current" 
                                strokeWidth="4" 
                                cx="50" 
                                cy="50" 
                                r="46" 
                                fill="none"
                            ></circle>
                            <circle 
                                className="text-violet-400 stroke-current" 
                                strokeWidth="4" 
                                strokeLinecap="round" 
                                cx="50" 
                                cy="50" 
                                r="46" 
                                fill="none"
                                strokeDasharray="289.5"
                                strokeDashoffset="144.75"
                                transform="rotate(-90 50 50)"
                            ></circle>
                        </svg>
                        
                        {/* Внутренний фон круга */}
                        <div className="w-10 h-10 bg-gradient-to-br from-[#2e1065] to-[#4c1d95] rounded-full flex items-center justify-center z-10" onClick={() => openModal('tire')}>
                            {/* Иконка в центре */}
                            <FaCar className="w-5 h-5 text-violet-300" />
                        </div>
                    </div>
                    <span className="text-xs text-gray-400 mt-1">Tire 50%</span>
                </div>
            </div>
            
            <CustomModalReact isOpen={isOpen} onClose={() => setIsOpen(false)}>
                <div>
                    <div className="bg-gradient-to-r from-violet-900/20 to-purple-900/20 rounded-2xl p-6 border border-violet-500/10">
                        <div className="mb-6">
                            <div className="text-center mb-4">
                                <div className="flex justify-center items-center gap-2 mb-2">
                                    {serviceConfig[serviceType].icon}
                                    <h2 className="text-xl font-bold text-white">{serviceConfig[serviceType].title}</h2>
                                </div>
                                <div className="text-sm text-gray-400">
                                    Всего: <span className="text-violet-300 font-medium">{total_distance.toFixed(0)} DP</span>
                                </div>
                            </div>
                            
                            <div className="flex items-center gap-4 mb-4">
                                <input
                                    type="range"
                                    min="1"
                                    max={serviceConfig[serviceType].maxDays}
                                    step="1"
                                    value={days}
                                    onChange={(e) => {
                                        const selectedDays = Number(e.target.value);
                                        setDays(selectedDays);
                                        updateCalculation(selectedDays, serviceType);
                                    }}
                                    className="w-full accent-violet-500 h-2 bg-violet-800/40 rounded-lg appearance-none cursor-pointer"
                                    style={{
                                        "--tw-range-thumb-bg": "theme('colors.violet.500')",
                                        "--tw-range-track-bg": "theme('colors.violet.800 / 0.3')"
                                    }}
                                />
                                <span className="text-md font-bold text-white min-w-[4.5rem] w-[4.5rem] text-right whitespace-nowrap">
                                    {days} {days === 1 ? 'день' : days < 5 ? 'дня' : 'дней'}
                                </span>
                            </div>

                            <div className="bg-violet-500/10 rounded-xl p-4 mb-4">
                                <div className="text-2xl font-bold text-white text-center">
                                    до {targetDate}
                                </div>
                            </div>

                            <div className="text-center">
                                <div className="text-violet-300 mb-1">Будет сожжено</div>
                                <div className="text-xl font-bold text-white">
                                    {Math.floor(pointsAmount)} Drive Points
                                </div>
                            </div>
                        </div>

                        <button 
                            className="w-full py-3 px-4 rounded-full bg-violet-500 hover:bg-violet-600 text-white font-medium transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                            disabled={pointsAmount <= 0 || pointsAmount > total_distance}
                        >
                            Заправить
                        </button>
                    </div>
                </div>
            </CustomModalReact>
        </div>
    );
};

export default ProkaChka;