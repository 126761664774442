import confetti from 'canvas-confetti';

export const createStars = () => {
    const defaults = {
      spread: 360,
      ticks: 100,
      gravity: 0,
      decay: 0.94,
      startVelocity: 20,
      shapes: ['circle'],
      colors: ['#FFE400', '#FFBD00', '#E89400', '#FFCA6C', '#FDFFB8']
    };

    function shoot() {
      confetti({
        ...defaults,
        particleCount: 40,
        scalar: 2,
        shapeOptions: {
          circle: {
            fill: '#FFD700'
          }
        }
      });

      confetti({
        ...defaults,
        particleCount: 10,
        scalar: 0.75,
        shapeOptions: {
          circle: {
            fill: '#FDB931'
          }
        }
      });
    }

    setTimeout(shoot, 0);
    setTimeout(shoot, 100);
    setTimeout(shoot, 200);
    setTimeout(shoot, 300);
}; 