// ToggleSwitch.js
import React, { useState } from 'react';

import { Form } from 'react-bootstrap';

function ToggleSwitch() {
    const [isChecked, setIsChecked] = useState(false);

    const handleToggle = () => {
        setIsChecked(!isChecked);
    };

    return (
        <Form>
            <Form.Check 
                type="switch"
                id="custom-switch"
                label=""
                checked={isChecked}
                onChange={handleToggle}
            />
        </Form>
    );
}

export default ToggleSwitch;