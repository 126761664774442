import React, { createContext, useState, useEffect } from 'react';
import confetti from 'canvas-confetti';
import { createStars } from './components/ConfettiEffect.js';
// Создаем контекст
export const UserContext = createContext();

// Создаем провайдер
export const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [distance, setDistance] = useState(0);
  const [total_distance, setTotal_distance] = useState(0.0);
  const [cards, setCards] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(false);
  const [tonConnect, setTonConnect] = useState(null);
  const [totalReferrals, setTotalReferrals] = useState(0);
  const [referralsclaim, setReferralsclaim] = useState(0);
  const [user_photo_url, setUser_photo_url] = useState(null);
  const [nfts, setNfts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [device_id, setDevice_id] = useState(null);
  const [clubsData, setClubsData] = useState({
    clubs: [],
    lastFetched: null
  });
  const [refParent1, setRefParent1] = useState(null);
  const [refParent2, setRefParent2] = useState(null);
  const [refParent3, setRefParent3] = useState(null);
  const [ton_adress_sbt_level_1, setTon_adress_sbt_level_1] = useState(null);
  const [ton_adress_sbt_level_2, setTon_adress_sbt_level_2] = useState(null);
  const [ton_adress_sbt_level_3, setTon_adress_sbt_level_3] = useState(null);
  const [ton_balance, setTon_balance] = useState(0.00);
  const [walletAddress, setWalletAddress] = useState(null);
  const [ton_adress_sbt, setTon_adress_sbt] = useState(null);
  const [clubs, setClubs] = useState(0);
  const Tabclick = () => {
    

    if (window.Telegram.WebApp && window.Telegram.WebApp.HapticFeedback) {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
    }
   
  
  }

  




  const fetchDataReferrals = async () => {
    setLoading(true);
    try {
      

      const response = await fetch(`https://drivecoin.io/api/friends/${user.user.id}?page=${page}&limit=10`);
      const results = await response.json();      
      const newCards = results.map(friend => ({
        title: friend.user_name_tg,
        img: friend.photo_url,
        level: friend.referral_level,
        distance: friend.total_distance, 
        first_name: friend.first_name
       
      }));
      
      setCards(prevCards => [...prevCards, ...newCards]);
    } catch (error) {
      console.error('Ошибка получения данных:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user && user.user.id && !initialLoad) {
      fetchDataReferrals();
      setInitialLoad(true);
    }
  }, [user, initialLoad]);

  useEffect(() => {
    if (user && user.user.id && page > 1) {
      fetchDataReferrals();
    }
  }, [page]);

  const updateClubs = (newClubs) => {
    setClubsData({
      clubs: newClubs,
      lastFetched: Date.now()
    });
  };

  return (
    <UserContext.Provider value={{
      user, setUser,
      distance, setDistance,
      total_distance, setTotal_distance,
      cards, setCards,
      setPage, loading,
      tonConnect, setTonConnect,
      totalReferrals, setTotalReferrals, 
      referralsclaim, setReferralsclaim,
      user_photo_url, setUser_photo_url,
      nfts, setNfts,
      isLoading, setIsLoading,
      Tabclick,
      createStars,
      device_id, setDevice_id,
      clubsData,
      updateClubs,
      clubs, setClubs,
      walletAddress, setWalletAddress, 
      refParent1, setRefParent1,
      refParent2, setRefParent2,
      refParent3, setRefParent3,
      ton_balance, setTon_balance,
      ton_adress_sbt, setTon_adress_sbt,
      ton_adress_sbt_level_1, setTon_adress_sbt_level_1,
      ton_adress_sbt_level_2, setTon_adress_sbt_level_2,
      ton_adress_sbt_level_3, setTon_adress_sbt_level_3
    }}>
      {children}
    </UserContext.Provider>
  );
};
